import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { OrganisationTypeActionEnum } from "../../modules/Organisations";
import { AppState } from "../../modules";

export const useController = ()=> {

    const dispatch = useDispatch();

    const allOrganisations = useSelector((state: AppState)=> state.OrganisationReducers.organisations);
    const getAllOrganisationFailure = useSelector((state: AppState)=> state.OrganisationReducers.getAllOrganisationFailure);

    const getAllOrganisation = useCallback(()=> {
        return dispatch({
            type: OrganisationTypeActionEnum.GET_ALL_ORGANISATION_REQUEST
        });
    }, [dispatch]);

    useEffect(()=> {
        getAllOrganisation();
    }, [
        getAllOrganisation,
    ]);

    return {

    }
}
