import React from "react";
import { LightMobileHeaderProps } from "./interface";
import styles from "./styles.module.css";
import goBack_icon from '../../../assets/icons/svgs/back-button.svg';
import { useNavigation, useScreenSize } from "../../../../../hooks";

export const MobileHeaderLight: React.FC<LightMobileHeaderProps> = ({
    title
}) => {
    const { isDesktop } = useScreenSize();
    const { goBack } = useNavigation()


    if (isDesktop) return null;
    return (
        <div className={styles.headerRow} >
            <img
                src={goBack_icon}
                alt="Icon_Cart"
                className={styles.iconLeft}
                onClick={goBack}
            />
            <div className={styles.iconsWrapper}>

                <p>{title}</p>
            </div>
        </div>
    )
}