import React from "react";
import { Bill } from "./Bill.component";
import bymeLogo from "../../assets/svgImages/bymeLogo.svg";
import { useGetSalesData, useSessionData } from "../../hooks";

export const BillScreeen = () => {
  const { sale } = useGetSalesData();
  const { sessionData } = useSessionData();

  return (
    <Bill
      sale={sale}
      logo={sessionData?.logos ? sessionData.logos.logoMediumImage : bymeLogo}
    />
  );
};
