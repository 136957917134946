import React, { useCallback } from "react";

import { BasketProductCardProps } from './interfaces';

import styles from './styles.module.css';

import {
    formatNumberToPrice,
    formatTextMaxChars_15,
    formatTextMaxChars_27,
    formatTextMaxChars_50,
} from "../../../../../functions";
import { QuantityActionButtons } from "../QuantityActionButtons";

import TrashIcon from '../../../assets/icons/svgs/trash.svg';
import { useScreenSize } from "../../../../../hooks";

export const BasketProductCard: React.FC<BasketProductCardProps> = ({
    uri,
    name,
    price,
    currency,
    description,
    quantity,
    onDeleteProduct,
    onQuantityDown,
    onQuantityUp,
}) => {
    const { isDesktop, isTablet } = useScreenSize()

    const renderMobileAndTableProductCard = useCallback(() => {
        if (isDesktop) return;
        return (
            <div className={styles.mobileCardContainer}>
                <div className={styles.itemGroup}>
                    <p className={styles.productItemName}>{formatTextMaxChars_15(name)}</p>
                    <p className={styles.productItemDescription}>{isTablet ? formatTextMaxChars_50(description) : formatTextMaxChars_27(description)}</p>
                    <div className={styles.separator} />
                </div>
                <div>
                    <QuantityActionButtons
                        quantity={quantity}
                        onAddQuantityProduct={onQuantityUp}
                        onDeleteQuantityProduct={onQuantityDown}
                    />
                </div>
                <p className={styles.itemPrice}>{price * quantity} {currency}</p>
            </div>
        )
    }, [currency, name, price, description, quantity, isDesktop, isTablet, onQuantityUp, onQuantityDown])

    const renderDesktopProducCard = useCallback(() => {
        if (!isDesktop) return;

        return (<div
            className={styles.productItemContainer}
        >
            <div className={styles.leftContentContainer}>
                <img
                    src={uri}
                    alt="product image"
                    className={styles.productImage}
                />


            </div>

            <div
                className={styles.productItemBodyContainer}
            >
                <div className={styles.productInfosContainer}>

                    <div>
                        <p className={styles.productItemName}
                        >{formatTextMaxChars_27(name)}</p>

                        <p className={styles.productItemDescription}>
                            {formatTextMaxChars_50(description)}
                        </p>
                    </div>
                    <img
                        src={TrashIcon}
                        alt="trash icon"
                        className={styles.trashIcon}
                        onClick={onDeleteProduct}
                    />
                </div>
                <div className={styles.productInfosContainer}>
                    <QuantityActionButtons
                        quantity={quantity}
                        onAddQuantityProduct={onQuantityUp}
                        onDeleteQuantityProduct={onQuantityDown}
                    />
                    <p className={styles.totalLabel}>{formatNumberToPrice(price * quantity)} {currency}</p>
                </div>
            </div>

        </div>)
    }, [
        currency,
        description,
        isDesktop,
        name,
        onDeleteProduct,
        onQuantityDown,
        onQuantityUp,
        price,
        quantity,
        uri
    ])

    return (
        <React.Fragment>
            {renderDesktopProducCard()}
            {renderMobileAndTableProductCard()}
        </React.Fragment>
    )
}