import React from "react";

import styles from './styles.module.css';
import {QuantityActionButtonsProps} from './interfaces';

export const QuantityActionButtons:React.FC<QuantityActionButtonsProps> = ({
    onAddQuantityProduct,
    onDeleteQuantityProduct,
    quantity
})=> {

    return (
        <div className={styles.actionButtonsGroupContainer}>
            <div className={styles.actionButtonsGroup}>
                <div 
                    className={styles.upDownButton}
                    onClick={onDeleteQuantityProduct}
                >
                    -
                </div>

                <label className={styles.quantity}>
                    {quantity}
                </label>

                <div 
                    className={styles.upDownButton}
                    onClick={onAddQuantityProduct}
                >
                    +
                </div>
            </div>
        </div>
    )
}