import { ProductCardProps } from "./interface"
import React, { useMemo } from "react"
import styles from "./styles.module.css";
import basket from '../../../assets/icons/svgs/basket-gold.svg';
import { useScreenSize } from "../../../../../hooks";
import { formatNumberToPrice, formatTextMaxChars_10, formatTextMaxChars_27, formatTextMaxChars_50 } from "../../../../../functions";
import { QuantityActionButtons } from "../QuantityActionButtons";

export const ProductCard: React.FC<ProductCardProps> = ({
    uri,
    name,
    price,
    currency,
    description,
    onSharePress,
    onProductPress,
    quantity,
    onDeleteProduct,
    onQuantityDown,
    onQuantityUp,
    handleBasketIconPress
}) => {

    const {
        isMobile
    } = useScreenSize();
    const productName = useMemo(() => {
        if (isMobile) {
            return formatTextMaxChars_10(name);
        } else {
            return formatTextMaxChars_27(name);
        }
    }, [isMobile, name]);
    return (
        <div className={styles.cardContainer} >
            <img
                onClick={() => {
                    onProductPress()
                }}
                src={uri}
                alt="product_image"
                className={styles.productImg}
            />
            <p
                onClick={() => {
                    onProductPress()
                }}
                className={styles.productName}>{productName}</p>
            <p className={styles.description}>{formatTextMaxChars_50(description)}</p>
            {/* <p className={styles.productName}>{`${price} ${currency}`}</p> */}
            <div className={styles.bottomRow}>
                <p>{`${formatNumberToPrice(price)} ${currency}`}</p>
                {!quantity ? <img
                    src={basket}
                    alt="Icon Cart"
                    className={styles.IconCart}
                    onClick={handleBasketIconPress}
                />
                    : (
                        <QuantityActionButtons
                            quantity={quantity!}
                            onAddQuantityProduct={onQuantityUp!}
                            onDeleteQuantityProduct={onQuantityDown!}

                        />
                    )
                }

            </div>
        </div>
    );
}