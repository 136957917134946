import React, { useCallback, useMemo, useState } from "react";

import { BasketProps } from "./interfaces";

import styles from "./styles.module.css";

import {
  BasketProductCard,
  Button,
  DesktopHedaderLight,
} from "../../components";
import { useNavigation, useScreenSize } from "../../../../hooks";
import { enumNavigationPath } from "../../../../enums";

import { formatNumberToPrice, formatTextMaxChars_15 } from "../../../../functions";
import goBack_icon from '../../assets/icons/svgs/back-button.svg';

export const BasketComponentTemplateB: React.FC<BasketProps> = ({
  sessionData,
  basket,
  onSelectedProductQuantityDown,
  onSelectedProductQuantityUp,
}) => {
  const { navigateTo, goBack } = useNavigation();
  const {
    isMobile,
    isDesktop
  } = useScreenSize();

  const totalBasket = useMemo(() => {
    let result = 0;
    basket.map((el) => {
      const price =
        el.productSellingPrice?.[el.productSellingPrice.length - 1].price;
      result += price * el.quantitySelected;
    });
    return result;
  }, [basket]);


  const handleValidedOrder = useCallback(() => {
    navigateTo(enumNavigationPath.livraison);
  }, [navigateTo]);

  const handlePressGoToCatalogue = useCallback(() => {
    navigateTo(enumNavigationPath.home);
  }, [navigateTo]);

  const handleGoBack = useCallback(() => {
    goBack();
  }, [goBack]);

  const renderMobileHeader = useCallback(() => {
    if (isDesktop) return;

    return (
      <div className={styles.headerRow} >
        <img
          src={goBack_icon}
          alt="Icon_Cart"
          className={styles.iconLeft}
          onClick={handleGoBack}
        />
        <div className={styles.iconsWrapper}>

          <p>Panier</p>
        </div>
      </div>

    )
  }, [handleGoBack, isDesktop])


  const renderRecapCard = useCallback(() => {
    if (!isDesktop) return;

    return (
      <div className={styles.recapContainer}>
        <p>A payer</p>
        <div className={styles.recapCardItemRow}>
          <p>Sous total</p>
          <p>{formatNumberToPrice(totalBasket)} Fcfa</p>
        </div>
        <div className={styles.recapCardItemRow}>
          <p>Frais de livraison estimée</p>
          <p>Offerts</p>
        </div>
        <div className={styles.totalItemRow}>
          <p>Total</p>
          <p>{formatNumberToPrice(totalBasket)} Fcfa</p>
        </div>
        <Button
          buttonName="Payer"
          onButtonPress={handleValidedOrder}
          containerStyle={{
            marginTop: '5%',
            alignSelf: 'center',
            padding: 0,
            width: '40%',
            paddingHorizontal: 10,
            paddingVertical: 5,
            height: 50,
            backgroundColor: '#F6D18D',
            borderRadius: 10

          }}
        />
      </div>
    )
  }, [handleValidedOrder, totalBasket, isDesktop])

  const renderProductCard = useCallback(() => {
    return (
      <div>
        {basket.map((el, i) => {
          const price =
            el.productSellingPrice?.[el.productSellingPrice.length - 1].price;
          return (
            <BasketProductCard
              key={i}
              uri={el.productImage}
              name={el.productName}
              description={el.productDescription}
              price={price}
              currency={"Fcfa"}
              quantity={el.quantitySelected}
              onDeleteProduct={() => onSelectedProductQuantityDown(basket, el)}
              onQuantityDown={() => onSelectedProductQuantityDown(basket, el)}
              onQuantityUp={() => onSelectedProductQuantityUp(basket, el)}
            />
          );
        })}
        {basket.length === 0 && (
          <div className={styles.emptyBasketText}>La panier est vide</div>
        )}
      </div>
    )
  }, [
    basket,
    onSelectedProductQuantityDown,
    onSelectedProductQuantityUp
  ])

  const renderProductDetails = useCallback(() => {

    return (
      <React.Fragment>
        <div className={styles.detailsCardContainer}>
          <p>Détails Commande</p>
          <div className={styles.separator} />
          <div>
            {
              basket.map((el, i) => {
                const price = el.productSellingPrice[el.productSellingPrice.length - 1].price;
                return (
                  <div key={i}
                    className={styles.recapCardItemRow}
                  >
                    <p>
                      {formatTextMaxChars_15(el.productName)} x{el.quantitySelected}
                    </p>
                    <p>
                      {formatNumberToPrice(price)} {'Fcfa'}
                    </p>

                  </div>
                )
              })
            }
          </div>
        </div>
        <div className={styles.separator} />
        <div className={styles.detailTotalContainer}>
          <p>A payer</p>
          <p>{formatNumberToPrice(totalBasket)} Fcfa</p>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            buttonName="Payer"
            onButtonPress={handleValidedOrder}
            containerStyle={{
              padding: (isMobile || isDesktop) ? 10 : 15,
              paddingLeft: (isMobile || isDesktop) ? 60 : 100,
              paddingRight: (isMobile || isDesktop) ? 60 : 100,
              fontSize: (isMobile || isDesktop) ? 14 : 20,
              marginTop: (isMobile || isDesktop) ? 0 : 20,
              marginBottom: (isMobile || isDesktop) ? 0 : 20,
              backgroundColor:'#F6D18D',
              borderRadius:10
            }}
          />
        </div>
      </React.Fragment>
    )
  }, [basket, totalBasket, handleValidedOrder, isMobile, isDesktop])

  return (

    <div className={styles.container}>
      <DesktopHedaderLight withSearch onPressExplorer={handlePressGoToCatalogue} />
      {renderMobileHeader()}
      {isDesktop ? (
        <React.Fragment>
          <p className={styles.pageTitle}>
            Panier
          </p>

          <div className={styles.contentContainer}>

            <div className={styles.separator} />
            <p className={styles.productCounts}>
              {basket.length} Produit(s)
            </p>
            <div className={styles.separator} />

            <div className={styles.separatedContent} >
              {renderProductCard()}
              {renderRecapCard()}
            </div>


          </div>
        </React.Fragment>
      ) :
        (
          <div className={styles.mobileContentContainer}>
            {renderProductCard()}
            {renderProductDetails()}
          </div>
        )
      }
    </div>
  );
};
