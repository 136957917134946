import React, { useCallback, useMemo } from "react";

import { BasketProps } from "./interfaces";

import styles from "./styles.module.css";

import { 
  BasketProductCard, 
  Button, 
  ScreenWrapper,
} from "../../components";
import { useNavigation, useScreenSize } from "../../../../hooks";
import { enumNavigationPath } from "../../../../enums";

import AddProductIcon from "../../assets/icons/svgs/plus.svg";
import IconLeft from '../../assets/icons/svgs/left_2.svg';
import { formatNumberToPrice } from "../../../../functions";

export const BasketComponentTemplateA: React.FC<BasketProps> = ({
  sessionData,
  basket,
  onSelectedProductQuantityDown,
  onSelectedProductQuantityUp,
}) => {
  const { navigateTo, goBack } = useNavigation();
  const {
    isMobile,
    isDesktop
  } = useScreenSize();

  const totalBasket = useMemo(() => {
    let result = 0;
    basket.map((el) => {
      const price =
        el.productSellingPrice?.[el.productSellingPrice.length - 1].price;
      result += price * el.quantitySelected;
    });
    return result;
  }, [basket]);

  const handleValidedOrder = useCallback(() => {
    navigateTo(enumNavigationPath.livraison);
  }, [navigateTo]);

  const handlePressGoToCatalogue = useCallback(() => {
    navigateTo(enumNavigationPath.home);
  }, [navigateTo]);

  const handleGoBack = useCallback(()=> {
    goBack();
  }, [goBack]);

  const renderFixedBottomSection = useCallback(() => {
    if (basket.length === 0) return;

    return (
      <div className={styles.fixedBottomContainer}>
        <div className={styles.fixedBottomSectionContainer}>
          <div className={styles.addCartImgContainer}>
            <img
              src={AddProductIcon}
              alt="add icon"
              className={styles.addCartImg}
              onClick={handlePressGoToCatalogue}
            />
          </div>

          <div className={styles.fixedBottomSectionSubContainer}>
            <div className={styles.totalContainer}>
              <p className={styles.totalLabel}>
                Total
              </p>

              <p className={styles.totalAmmount}>
                {formatNumberToPrice(totalBasket)} Fcfa
              </p>
            </div>

            <Button
              buttonName="Passer ma commande"
              onButtonPress={handleValidedOrder}
              containerStyle={{
                fontSize: isMobile ? 12 : 20,
                padding: isMobile ? 8 : 12,
                paddingLeft: isMobile ? 20 : 40,
                paddingRight: isMobile ? 20 : 40,
              }}
            />
          </div>
        </div>
      </div>
    );
  }, [
    isMobile,
    basket, 
    handlePressGoToCatalogue, 
    handleValidedOrder, 
    totalBasket,
  ]);

  const renderDesktopHeader = useCallback(()=> {
    if(!isDesktop) return;

    return (
      <div className={styles.desktopHeaderContainer}>
        <img 
          src={IconLeft}
          alt="icon left"
          className={styles.desktopIconLeft}
          onClick={handleGoBack}
        />

        <p className={styles.pageTitleDestop}>
          Panier
        </p>
      </div>
    )
  }, [
    isDesktop,
    handleGoBack,
  ])

  return (
    <ScreenWrapper
      onGoLeftPress={handleGoBack}
    >
      <div className={styles.container}>

        {
          !isDesktop && (
            <p className={styles.pageTitle}>
              Panier
            </p>
          )
        }

        {renderDesktopHeader()}

        <div className={styles.basketContainer}>
          {basket.map((el, i) => {
            const price =
              el.productSellingPrice?.[el.productSellingPrice.length - 1].price;
            return (
              <BasketProductCard
                key={i}
                uri={el.productImage}
                name={el.productName}
                description={el.productDescription}
                price={price}
                currency={"Fcfa"}
                quantity={el.quantitySelected}
                onDeleteProduct={() => onSelectedProductQuantityDown(basket, el)}
                onQuantityDown={() => onSelectedProductQuantityDown(basket, el)}
                onQuantityUp={() => onSelectedProductQuantityUp(basket, el)}
              />
            );
          })}
          {basket.length === 0 && (
            <div className={styles.emptyBasketText}>La panier est vide</div>
          )}
        </div>

        {renderFixedBottomSection()}
      </div>
    </ScreenWrapper>
  );
};
