import React, { useCallback, useMemo, useState } from "react";

import { OrderTrackingProps } from './interfaces';

import styles from './styles.module.css';
import OrderTrackingImg from '../../assets/icons/svgs/orderTraking.svg';
import OrderStepper from '../../assets/icons/svgs/orderStepper.svg';
import { enumToStringArray } from "../../../../functions";
import { OnlineOrderStatusEnum } from "../../../../types";
import { Button, Input } from "../../components";
import { Stepper } from "./components";
import { InputStyle } from "../../components/Input/interfaces";
import { useScreenSize } from "../../../../hooks";


export const OrderTrackingComponentB: React.FC<OrderTrackingProps> = ({
    sale,
    onGetOrderNumber,
}) => {
    const { isDesktop } = useScreenSize()

    const [orderNumber, setOrderNumber] = useState<string>('');

    const steps = useMemo(() => {
        return enumToStringArray(OnlineOrderStatusEnum);
    }, []);

    const activeStep = useMemo(() => {
        return steps.indexOf(sale?.onlineOrderStatus!);
    }, [steps, sale]);

    const stepDescription = useMemo(() => {
        switch (activeStep) {
            case 0:
                return 'Le détaillant a confirmé la commande.';
            case 1:
                return "Le détaillant prépare l'article pour l'expédition";
            case 2:
                return "L'article a été donné au transporteur pour livraison. ";
            case 3:
                return "Votre colis vous a été livré à l'adresse indiqué";
        }
    }, [activeStep]);

    const handlePressButton = useCallback(() => {
        if (orderNumber) {
            onGetOrderNumber(orderNumber);
        }
    }, [onGetOrderNumber, orderNumber]);

    const renderInputOrderNumberComponent = useCallback(() => {
        if (isDesktop) return;
        return (
            <div className={styles.subContainer}>
                <p
                    className={styles.titlePage}
                >
                    Pour suivre votre commande, entrez l’ID que vous avez reçu par mail.
                </p>

                <img
                    src={OrderTrackingImg}
                    alt="img"
                />

                <Input
                    placeholder="Numero de commande"
                    value={orderNumber}
                    onChangeText={setOrderNumber}
                    inputStyle={InputStyle.filled}
                    customStyle={{
                        borderRadius: 30,
                        borderWidth: 1,
                        width: 300,
                        paddingLeft: 20,
                    }}
                />

                <Button
                    buttonName="Valider"
                    onButtonPress={handlePressButton}
                    containerStyle={{
                        backgroundColor: ' #F6D18D',
                        width: 200,
                        height: 10,
                        borderRadius: 10
                    }}
                />
            </div>
        )
    }, [handlePressButton, isDesktop, orderNumber]);

    const renderInputOrderNumberDesktopComponent = useCallback(() => {
        if (!isDesktop) return;
        return (
            <div className={styles.subContainer}>
                <div className={styles.desktopFirstRowchild}>
                    <p
                        className={styles.titlePage}
                    >
                        Pour suivre votre commande, entrez l’ID que vous avez reçu par mail.
                    </p>
                    <Input
                        placeholder="Numero de commande"
                        value={orderNumber}
                        onChangeText={setOrderNumber}
                        inputStyle={InputStyle.filled}
                        customStyle={{
                            borderRadius: 50,
                            borderWidth: 1,
                            width: 415,
                            height: 50,
                            paddingLeft: 20,
                        }}
                    />
                    <Button
                        buttonName="Valider"
                        onButtonPress={handlePressButton}
                        containerStyle={{
                            backgroundColor: ' #F6D18D',
                            width: 120,
                            height: 25,
                            borderRadius: 10,
                            marginTop: 140
                        }}
                    />
                </div>

                <img
                    src={OrderTrackingImg}
                    alt="img"
                    className={styles.desktopImage}
                />
            </div>
        )
    }, [handlePressButton, isDesktop, orderNumber]);

    const renderContent = useCallback(() => {

        return (
            <React.Fragment>
                {renderInputOrderNumberComponent()}
                {renderInputOrderNumberDesktopComponent()}
            </React.Fragment>
        )
    }, [renderInputOrderNumberComponent, renderInputOrderNumberDesktopComponent])

    const renderOrderSteper = useCallback(() => {
        return (
            <div className={styles.subContainer_2}>
                <img
                    src={OrderStepper}
                    alt="img"
                    className={styles.orderStepperImg}
                />

                <p className={styles.orderStepperTxt}>
                    Ou se trouve votre commande ?
                </p>

                <div className={styles.stepperContainer}>
                    <Stepper
                        activeStep={activeStep}
                    >
                        <h4 style={{ textAlign: 'center' }}
                        >{stepDescription}</h4>
                    </Stepper>
                </div>
            </div>
        )
    }, [stepDescription, activeStep]);

    return (
        <div className={styles.container}>
            {
                sale ?
                    renderOrderSteper()
                    : renderContent()
            }
        </div>
    )
}