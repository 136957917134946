import React, { useMemo } from "react";
import { useController } from "./useController";
import { RecapComponentTemplateA, RecapComponentTemplateB } from "../../templates";

export const Recap: React.FC = () => {
  
  const {
    basket,
    deliveryData,
    sessionData,
  } = useController();

  const templateType = useMemo(()=> {
    return sessionData?.template!
  }, [sessionData]);

  const TEMPLATES:any = useMemo(() => {
    return {
      A: RecapComponentTemplateA,
      B: RecapComponentTemplateB,
    }
  }, []);

  const TemplateComponent = useMemo(() => {
    return TEMPLATES[templateType];
  }, [
    TEMPLATES,
    templateType
  ]);

  return (
    <TemplateComponent
      basket={basket}
      deliveryData={deliveryData}
    />
  );
};
