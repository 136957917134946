export interface Organisation {
  _id: string;
  _organisationType: OrganisationTypeEnum | string;
  organisationName: string;
  organisationNameParsed: string;
  template?: string;
  city: string;
  country: string;
  logos?: logosType;
  createAt: string;
}
export interface logosType {
  logoSmallImage: string;
  logoSmallImageRef: string;
  logoMediumImage: string;
  logoMediumImageRef: string;
  logoLargeImage: string;
  logoLargeImageRef: string;
}
export enum OrganisationTypeEnum {
  Restaurant = "Restaurant",
  Shop = "Shop",
}
export type OrganisationActionType = {
  type: string;
  payload: any;
};

export interface OrganisationInitialState {
  organisationData: Organisation | null;
  getOrganisationDataError: boolean;
  organisations: Organisation[],
  getAllOrganisationFailure: boolean;
}
