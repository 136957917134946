import React, { useEffect, useState } from "react";
import { Button, Input, MobilAndTableHead } from "../../components";

import { LivraisonProps } from "./interfaces";

import styles from "./styles.module.css";
import { useNavigation } from "../../../../hooks";
import { enumNavigationPath } from "../../../../enums";

export const LivraisonComponentTemplateA: React.FC<LivraisonProps> = ({
  clientData,
  onSetClientDataLivraison,
}) => {
  const {navigateTo} = useNavigation();

  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [country, setCountry] = useState<string>("Côte d'Ivoire");
  const [city, setCity] = useState<string>("Abidjan");
  const [street, setStreet] = useState<string>("");
  const [adress, setAdress] = useState<string>("");

  useEffect(() => {
    if (clientData) {
      setFirstname(clientData.firstname);
      setLastname(clientData.lastname);
      setPhone(clientData.phone);
      setCountry(clientData.country);
      setCity(clientData.city);
      setStreet(clientData.street);
      setAdress(clientData.adress);
    }
  }, [clientData]);

  const handleButtonPress = () => {
    if (firstname && lastname && phone && street) {
      onSetClientDataLivraison({
        firstname,
        lastname,
        phone,
        country,
        city,
        street,
        adress,
      });
      navigateTo(enumNavigationPath.recap);
    } else {
      alert("* Tous les champs doivent etre remplis !");
    }
  };

  return (
    <div className={styles.container}>

      <MobilAndTableHead />
      
      <div className={styles.subContainer}>
        <div className={styles.deliveryImg}>
          <h3 className={styles.deliveryTitle}>
            Livraison
          </h3>
        </div>

        <div className={styles.formContainer}>

          <p className={styles.clientInfoTitle}
          >* Informations de livraison</p>

          <div className={styles.inputsContainer}>
            <Input
              placeholder="Prenom"
              value={firstname}
              onChangeText={setFirstname}
              inputType="text"
            />
            <Input
              placeholder="Nom"
              value={lastname}
              onChangeText={setLastname}
              inputType="text"
            />
            <Input
              placeholder="Téléphone"
              value={phone}
              onChangeText={setPhone}
              inputType="number"
            />
            <Input
              placeholder="Pays"
              value={country}
              onChangeText={setCountry}
              inputType="text"
              disabled
            />
            <Input
              placeholder="Ville"
              value={city}
              onChangeText={setCity}
              inputType="text"
              disabled
            />
            <Input
              placeholder="Quartier"
              value={street}
              onChangeText={setStreet}
              inputType="text"
            />
            <textarea
              placeholder="Adresse"
              value={adress}
              onChange={(e) => setAdress(e.target.value)}
              rows={6}
              className={styles.textarea}
            />
          </div>

          <Button
            buttonName="Suivant" 
            onButtonPress={handleButtonPress} 
            containerStyle={{
              marginBottom: 20,
              padding: 10,
              paddingLeft: 40,
              paddingRight: 40
            }}
          />
        </div>
      </div>
    </div>
  );
};
