import { OrganisationActionType, OrganisationInitialState } from "../../types";
import { OrganisationTypeActionEnum } from "./actions";

const initialState: OrganisationInitialState = {
  organisationData: null,
  getOrganisationDataError: false,
  organisations: [],
  getAllOrganisationFailure: false,
};

export default function OrganisationReducers(
  state: OrganisationInitialState = initialState,
  { type, payload }: OrganisationActionType
) {
  switch (type) {
    case OrganisationTypeActionEnum.GET_ORGANISATION_DATA_SUCCESSS:
      return {
        ...state,
        organisationData: payload,
      };
    case OrganisationTypeActionEnum.GET_ORGANISATION_DATA_FAILURE:
      return {
        ...state,
        getOrganisationDataError: payload,
      };
    case OrganisationTypeActionEnum.GET_ALL_ORGANISATION_SUCCESS:
      return {
        ...state,
        organisations: payload
      }
    case OrganisationTypeActionEnum.GET_ALL_ORGANISATION_FAILURE:
      return {
        ...state,
        getAllOrganisationFailure: payload
      }
    default:
      return state;
  }
}
