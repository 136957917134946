import React, { useMemo } from "react";
import { useController } from "./useController";
import { SuccessComponentTemplateA, SuccessComponentTemplateB } from "../../templates";

export const Success: React.FC = () => {
  
  const {
    sessionData,
    handleOpenNewTab,
    onCleanBasket,
  } = useController();

  const templateType = useMemo(()=> {
    return sessionData?.template!
  }, [sessionData]);

  const TEMPLATES:any = useMemo(() => {
    return {
      A: SuccessComponentTemplateA,
      B: SuccessComponentTemplateB,
    }
  }, []);

  const TemplateComponent = useMemo(() => {
    return TEMPLATES[templateType];
  }, [
    TEMPLATES,
    templateType
  ]);

  return (
    <TemplateComponent
      onDownloadPress={handleOpenNewTab}
      onCleanBasket={onCleanBasket}
    />
  );
};
