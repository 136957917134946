import React, { useCallback, useMemo, useState } from "react";

import {
  ScreenWrapper,
  ProductCard,
  Button,
  Input,
  ResponsiveCarousel,
} from '../../components';

import { HomeProps } from "./interfaces";

import styles from "./styles.module.css";
import { enumNavigationPath } from "../../../../enums";
import { useScreenSize, useSessionData } from "../../../../hooks";
import { DesktopHead } from "../../../template_A/components";

export const HomeComponentTemplateA: React.FC<HomeProps> = ({
  carrouselData,
  productByCategories,
  navigateTo,
  onSelectedProduct,
  onSelectedCategory,
}) => {

  const {isDesktop} = useScreenSize();
  const { sessionData } = useSessionData();

  const organisationLogo = useMemo(()=> {
    return sessionData?.logos?.logoMediumImage;
  }, [sessionData]);

  const [search, setSearch] = useState<string>('');

  const handleGoToBasket = useCallback(()=> {
    navigateTo(enumNavigationPath.basket);
  }, [navigateTo]);

  const renderInpput = useCallback(()=> {
    if(isDesktop) return;

    return (
      <Input 
        value={search}
        onChangeText={setSearch}
        placeholder="Recherche"
      />
    )
  }, [isDesktop, search]);

  const renderCarrousel = useCallback(()=> {
    if(isDesktop) return;

    return (
      <div className={styles.carouselContainer}>
        <ResponsiveCarousel 
          carrouselArray={carrouselData} 
        />
      </div>
    )
  }, [carrouselData, isDesktop]);

  const renderDesktopHeader = useCallback(()=> {
    if(!isDesktop) return;
    return (
      <DesktopHead
        carrouselArray={carrouselData} 
        search={search}
        organisationLogo={organisationLogo!}
        setSearch={setSearch}
        onGoCartPress={handleGoToBasket}
      />
    )
  }, [
    carrouselData, 
    organisationLogo, 
    search, 
    isDesktop,
    handleGoToBasket,
  ]);

  return (
    <ScreenWrapper
      onGoCartPress={handleGoToBasket}
    >
      <div className={styles.container}>

        {renderDesktopHeader()}
        
        {renderInpput()}

        {renderCarrousel()}

        <div className={styles.productsContainer}>
          {productByCategories.map((category, i) => {
            return (
              <div 
                key={i}
                className={styles.categoryContainer}
              >
                <p className={styles.categoryNameStyle}>
                  {category.categoryName}
                </p>
                <div className={styles.categorySubContainer}>
                  {category.produits.slice( isDesktop ? -4 : -2).map((product, j) => {
                    const price =
                      product.productSellingPrice![
                        product.productSellingPrice?.length! - 1
                      ].price;
                    return (
                      <ProductCard
                        key={j}
                        name={product.productName}
                        description={product.productDescription}
                        price={price}
                        currency={"Fcfa"}
                        uri={product.productImage}
                        onSharePress={() => {}}
                        onProductPress={() => {
                          onSelectedProduct(product);
                          onSelectedCategory(category);
                          navigateTo(enumNavigationPath.product);
                        }}
                      />
                    );
                  })}
                </div>

                <div className={styles.buttoncontainer}>
                  <Button
                    buttonName={"Voir plus"}
                    onButtonPress={() => {
                      onSelectedCategory(category);
                      navigateTo(enumNavigationPath.carte);
                    }}
                    containerStyle={{
                      width: isDesktop ? '30%' : '80%',
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </ScreenWrapper>
  );
};
