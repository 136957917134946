import React, { useMemo } from "react";
import {  PaymentMethodeEnum } from "../../types";
import { useController } from "./useController";
import { PaymentComponentTemplateA, PaymentComponentTemplateB } from "../../templates";

export const Payment: React.FC = () => {

  const {
    basket,
    deliveryData,
    sessionData,
    paymentTypeSave,
    onSelectedPayment,
    onAddNewSale,
  } = useController();

  const templateType = useMemo(()=> {
    return sessionData?.template!
  }, [sessionData]);

  const TEMPLATES:any = useMemo(()=> {
    return {
      A: PaymentComponentTemplateA,
      B:PaymentComponentTemplateB
    }
  }, []);

  const TemplateComponent = useMemo(()=> {
    return TEMPLATES[templateType];
  }, [
    TEMPLATES, 
    templateType
  ]);
  
  return (
    <TemplateComponent
      paymentTypeSave={paymentTypeSave ? paymentTypeSave : PaymentMethodeEnum.esp}
      onSelectedPayment={onSelectedPayment}
      onAddNewSale={onAddNewSale}
      sessionData={sessionData}
      basket={basket}
      deliveryData={deliveryData}
    />
  );
};
