import React, { useCallback, useMemo } from "react";

import {ShopsCardSliderProps} from './interfaces';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import styles from './styles.module.css';
import { ShopCard } from "../ShopCard";
import { useScreenSize } from "../../../../hooks";

const RenderNextArrow = (props: any)=> {
    const { onClick } = props;

    return (
        <div
            className={styles.nextArrowStyles}
            onClick={onClick}
        >
            {'>'}
        </div>
    );
}
  
const RenderPrevArrow = (props: any)=> {
    const { onClick } = props;

    return (
        <div
            className={styles.prevArrowStyles}
            onClick={onClick}
        >
            {'<'}
        </div>
    );
}

export const ShopsCardSlider: React.FC<ShopsCardSliderProps> = ({
    shopCardList
}) => {

    const {isDesktop} = useScreenSize();

    const settings = useMemo(()=> {

        let arrows = isDesktop ? {
            nextArrow: <RenderNextArrow />,
            prevArrow: <RenderPrevArrow />
        } : {};

        return {
            ...arrows,
            infinite: true,
            swipeToSlide: true,
            variableWidth: true,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 300,
        }
    }, [isDesktop]);

    const renderSlider = useCallback(()=> (
        <Slider {...settings}>
            {
                shopCardList.map((item, i)=> {
                    return (
                        <div
                            key={i}
                        >
                            <ShopCard
                                key={i}
                                shopName={item.shopName}
                                backgroundImg={item.backgroundImg}
                                products={item.products}
                                cardColor={item.cardColor}
                            />
                        </div>
                    )
                })
            }
        </Slider>
    ), [
        settings,
        shopCardList
    ]);
    
    return (
        <div 
            className={styles.container}
            style={
                isDesktop ? {
                    display: 'flex',
                    justifyContent: 'center'
                } : {}
            }
        >
            {
                isDesktop ? (
                    <div className={styles.subContainer}>
                        {renderSlider()}
                    </div>
                ) : renderSlider()
            }
        </div>
    )
}
