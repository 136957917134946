import React, { useEffect, useMemo, useRef } from "react";

import { InputProps, InputStyle } from './interfaces';

import styles from './styles.module.css';

export const Input: React.FC<InputProps> = ({
    placeholder,
    value,
    onChangeText,
    inputType = 'text',
    disabled,
    customStyle,
    placeholderColor,
    inputStyle = "bottomBordered",
    withBorders = false
}) => {
    const containerStyle = useMemo(() => {
        const filledInputWithBorder = disabled ? styles.filledInputWithBorderDisabled : styles.filledInputWithBorder;
        const filledInput = disabled ? styles.filledInput : styles.filledInputDisabled
        return {
            [InputStyle.bottomBordered]: styles.container,
            [InputStyle.filled]: withBorders ? filledInputWithBorder : filledInput
        }
    }, [disabled, withBorders]);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (placeholderColor && inputRef.current) {
            const styleEl = document.createElement('style');
            document.head.appendChild(styleEl);
            styleEl.sheet!.insertRule(`
                input.${styles.container}::placeholder {
                    color: ${placeholderColor};
                }
            `);
            return () => {
                document.head.removeChild(styleEl);
            };
        }
    }, [placeholderColor]);

    return (
        <input
            ref={inputRef}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={e => onChangeText(e.target.value)}
            type={inputType}
            className={containerStyle[inputStyle as InputStyle]}
            style={customStyle}
        />

    )
}