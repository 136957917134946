import React, { useCallback } from "react";
import { LightDesktopHeaderProps } from "./interface";
import { Input } from "../../Input";
import styles from "./styles.module.css";
import IconCartDark from '../../../assets/icons/svgs/basket-dark.svg';
import explorerDark from '../../../assets/icons/svgs/explorer-dark.svg';
import { useNavigation, useScreenSize, useSessionData } from "../../../../../hooks";
import { enumNavigationPath } from "../../../../../enums";

export const DesktopHedaderLight: React.FC<LightDesktopHeaderProps> = ({
    onPressBasket,
    onPressExplorer,
    withSearch = false
}) => {
    const { isDesktop } = useScreenSize();
    const { sessionData } = useSessionData()
    const { navigateTo } = useNavigation()

    const goToHome = useCallback(() => {
        navigateTo(enumNavigationPath.home);
    }, [navigateTo]);

    const handleBasketPress = useCallback(() => {
        if (!onPressBasket) return
        onPressBasket()
    }, [onPressBasket])

    const handleExplorPress = useCallback(() => {
        if (!onPressExplorer) return
        onPressExplorer()
    }, [onPressExplorer])

    if (!isDesktop) return null;
    return (
        <div className={styles.headerContainer}>
            <img
                onClick={goToHome}
                src={sessionData?.logos?.logoSmallImage}
                alt="logo"
                className={styles.headerLogo}
            />
            <div className={styles.secondHeaderRow}>
                {withSearch && (
                    <div className={styles.inputWrapper}>
                        <Input
                            disabled
                            customStyle={{ borderColor: 'black', color: 'black' }}
                            placeholder={'Recherche'}
                            value={'Recherche'}
                            onChangeText={() => { }}
                        />
                    </div>
                )}
                <div className={styles.explorerWrapper}>
                    <img
                        onClick={handleExplorPress}
                        src={explorerDark}
                        alt="Explorer" />
                </div>
                <div className={styles.basketWrapper}>
                    <img
                        onClick={handleBasketPress}
                        src={IconCartDark}
                        alt="Basket_Icon"
                    />
                </div>

            </div>
        </div>
    )
}