import React, { useCallback, useMemo } from "react";

import { ProductProps } from "./interfaces";

import styles from "./styles.module.css";
import { Button, ProductCard, QuantityActionButtons } from "../../components";
import { useNavigation, useScreenSize } from "../../../../hooks";
import { enumNavigationPath } from "../../../../enums";

import { formatNumberToPrice } from "../../../../functions";
import { Product } from "../../../../types";
import IconCart_gold from '../../assets/icons/svgs/basket-gold.svg';
import goBack from '../../assets/icons/svgs/back-button.svg';
import explorer from '../../assets/icons/svgs/explorer-dark.svg';
export const ProductComponentTemplateB: React.FC<ProductProps> = ({
  selectedProduct,
  basket,
  selectedCategory,
  onSelectedProductQuantityUp,
  onSelectedProductQuantityDown,
  onAddProductInBasket,
  onSelectedProduct,
  onSelectedCategory
}) => {

  const { navigateTo } = useNavigation();
  const { isDesktop } = useScreenSize();

  const elementExist = useMemo(() => {
    return basket.find((ele) => ele._id === selectedProduct._id)
  }, [basket, selectedProduct]);

  const handlePressGoToCatalogue = useCallback(() => {
    navigateTo(enumNavigationPath.home);
  }, [navigateTo]);
  
  const price = useMemo(() => {
    const priceTmp = selectedProduct?.productSellingPrice![
      selectedProduct?.productSellingPrice?.length! - 1
    ].price;

    if (elementExist) {
      return priceTmp * elementExist.quantitySelected;
    }

    return priceTmp!
  }, [selectedProduct, elementExist, basket]);

  const handleGoBack = useCallback(() => {
    window.history.go(-1);
  }, []);
  const elementEx = useMemo(() => basket.find((ele) => ele._id === selectedProduct._id), [basket, selectedProduct]);
  const quantity = elementEx && elementEx.quantitySelected;
  const onAddQuantityProduct = useCallback(() => {
    if (!elementExist) {
      onAddProductInBasket(
        basket,
        {
          ...selectedProduct,
          quantitySelected: 1,
        },
      );
    } else {
      onSelectedProductQuantityUp(basket, elementExist);
    }
  }, [
    basket,
    onAddProductInBasket,
    onSelectedProductQuantityUp,
    selectedProduct,
    elementExist
  ]);

  const onDeleteQuantityProduct = useCallback(() => {
    if (elementExist) {
      onSelectedProductQuantityDown(basket, elementExist);
    }
  }, [
    basket,
    onSelectedProductQuantityDown,
    elementExist
  ]);

  const handleCartPress = useCallback(() => {
    navigateTo(enumNavigationPath.basket);
  }, [navigateTo]);

  const renderHeader = useCallback(() => (
    <div className={styles.headerRow} >
      <img
        src={goBack}
        alt="Icon Cart"
        className={styles.iconLeft}
        onClick={handleGoBack}
      />
      <div className={styles.iconsWrapper}>

        <div className={styles.explorerWrapper}>
          <img
            src={explorer}
            alt="Explorer button"
            className={styles.iconCart}
            onClick={() => { }}
          />
        </div>
        <div className={styles.basketWrapper}>
          <img
            src={IconCart_gold}
            alt="Icon Cart"
            className={styles.iconCart}
            onClick={handleCartPress}
          />
        </div>
      </div>
    </div>

  ), [handleCartPress, handleGoBack])

  const renderMobileAndTableContent = useCallback(() => {
    if (isDesktop) return;

    return (
      <React.Fragment>
        <div
          style={{
            backgroundImage: `url(${selectedProduct.productImage})`
          }}
          className={styles.productImg}
        >
          <div className={styles.productImgOverlay} />
          <p className={styles.productName}>
            {selectedProduct.productName}
          </p>

        </div>

        <div className={styles.productDetailContainer}>
          <p className={styles.productNameTitle}>
            {selectedProduct.productName}
          </p>
          <p className={styles.descriptionTitle}>Infos:</p>
          <p className={styles.productDescription}>{selectedProduct.productDescription}</p>
          <div className={styles.bottomRow}>
            <p>{`${formatNumberToPrice(price)} ${'Fcfa'}`}</p>
            {!quantity ? (
              <img
                src={IconCart_gold}
                alt="Icon Cart"
                className={styles.IconCart}
                onClick={onAddQuantityProduct}
              />
            )
              : (
                <QuantityActionButtons
                  quantity={quantity!}
                  onAddQuantityProduct={onAddQuantityProduct!}
                  onDeleteQuantityProduct={onDeleteQuantityProduct}

                />
              )
            }

          </div>
        </div>
        <div className={styles.separotorcontainer}>
          <div className={styles.separator} />
        </div>
      </React.Fragment>
    )
  }, [
    onAddQuantityProduct,
    onDeleteQuantityProduct,
    price,
    quantity,
    selectedProduct,
    isDesktop
  ])

  const renderDesktopContent = useCallback(() => {
    if (!isDesktop) return;

    return (
      <div className={styles.desktopContentContainer} >
        <div
          style={{
            backgroundImage: `url(${selectedProduct.productImage})`
          }}
          className={styles.deskTopProductImg}
        >
          <div className={styles.desktopProductImgOverlay} />
          <p className={styles.productName}>
            {selectedProduct.productName}
          </p>

        </div>
        <div className={styles.productDetailContainer}>
          <p className={styles.productNameTitle}>
            {selectedProduct.productName}
          </p>
          <p className={styles.descriptionTitle}>Infos:</p>
          <p className={styles.productDescription}>{selectedProduct.productDescription}</p>
          <div className={styles.bottomRow}>
            <p>{`${formatNumberToPrice(price)} ${'Fcfa'}`}</p>
            {!quantity ? (
              <Button
                buttonName="Ajouter au panier"
                onButtonPress={onAddQuantityProduct}
                containerStyle={{
                  padding: 0,
                  width: '40%',
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  height: 50,
                  backgroundColor: '#F6D18D',
                  borderRadius: 10

                }}
              />
            )
              : (
                <QuantityActionButtons
                  quantity={quantity!}
                  onAddQuantityProduct={onAddQuantityProduct!}
                  onDeleteQuantityProduct={onDeleteQuantityProduct}

                />
              )
            }

          </div>
        </div>
      </div>
    )
  }, [
    isDesktop,
    selectedProduct,
    onAddQuantityProduct,
    onDeleteQuantityProduct,
    price,
    quantity
  ])

  const renderContent = useCallback(() => {

    return (
      <div>
        {renderHeader()}
        {renderMobileAndTableContent()}
        {renderDesktopContent()}
      </div>

    )
  }, [renderHeader, renderMobileAndTableContent, renderDesktopContent]);

  const renderSuggestionProducts = useCallback(() => {
    return (
      <React.Fragment>
        <div className={styles.suggestionRowHeader}>
          <p className={styles.suggestionTitle}>A découvrir</p>
          <p onClick={() => {
            onSelectedCategory(selectedCategory);
            navigateTo(enumNavigationPath.carte);
          }} className={styles.suggestionHeaderLink}>Voir plus</p>
        </div>
        <div className={styles.suggestionProductContainer}>
          <div className={styles.suggestionProductSubContainer}>
            {
              selectedCategory?.produits?.map((product: Product, i: number) => {
                const productPrice = product.productSellingPrice[product.productSellingPrice.length - 1].price;
                const elementEx = basket.find((ele) => ele._id === product._id);
                const quantity = elementEx && elementEx.quantitySelected;
                return (
                  <div
                    key={i}
                    className={styles.suggestionProductItemContainer}
                  >
                    <ProductCard
                      uri={product.productImage}
                      name={product.productName}
                      price={Number(productPrice)}
                      currency={'fcfa'}
                      description={product.productDescription}
                      onSharePress={() => { }}
                      onProductPress={() => {
                        onSelectedProduct(product);
                        navigateTo(enumNavigationPath.product);
                      }}
                      handleBasketIconPress={() => {
                        onAddProductInBasket(
                          basket,
                          {
                            ...product,
                            quantitySelected: 1,
                          },
                        );
                      }}
                      elementExist={!!elementEx}
                      onQuantityUp={() => {
                        onAddProductInBasket(
                          basket,
                          {
                            ...product,
                            quantitySelected: 1,
                          },
                        );
                      }}
                      onQuantityDown={() => onSelectedProductQuantityDown(basket, elementEx!)}
                      quantity={quantity}
                    />
                  </div>
                )
              })
            }
          </div>
        </div></React.Fragment>
    )
  }, [
    selectedCategory,
    onSelectedCategory,
    navigateTo,
    basket,
    onSelectedProduct,
    onAddProductInBasket,
    onSelectedProductQuantityDown
  ]);

  return (
    <div className={styles.container}>
      {renderContent()}
      {renderSuggestionProducts()}

    </div>
  );
};
