import React, { useMemo } from "react";
import { useController } from "./useController";
import { BasketComponentTemplateA } from "../../templates/template_A";
import { BasketComponentTemplateB } from "../../templates";

export const Basket:React.FC = ()=> {

  const {
    sessionData,
    basket,
    onSelectedProductQuantityUp,
    onSelectedProductQuantityDown,
  } = useController();

  const templateType = useMemo(()=> {
    return sessionData?.template!
  }, [sessionData]);

  const TEMPLATES: any = useMemo(()=> {
    return {
      A: BasketComponentTemplateA,
      B: BasketComponentTemplateB
    }
  }, []);

  const TemplateComponent = useMemo(()=> {
    return TEMPLATES[templateType];
  }, [
    TEMPLATES, 
    templateType
  ]);

  return (
    <TemplateComponent 
      sessionData={sessionData}
      basket={basket}
      onSelectedProductQuantityUp={onSelectedProductQuantityUp}
      onSelectedProductQuantityDown={onSelectedProductQuantityDown}
    />
  )
}
