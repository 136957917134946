import React, { useCallback, useMemo, useState } from "react";



import { HomeProps } from "./interfaces";
import { useScreenSize, useSessionData } from "../../../../hooks";
import { DesktopHeader } from "../../components/templates/DesktopHeader";
import { MobileHeader, ProductCard } from "../../components";
import styles from "./styles.module.css";
import { enumNavigationPath } from "../../../../enums";
export const HomeComponentTemplateB: React.FC<HomeProps> = ({
  carrouselData,
  productByCategories,
  navigateTo,
  onSelectedProduct,
  onSelectedCategory,
  basket,
  onAddProductInBasket,
  onSelectedProductQuantityDown,
}) => {

  const { isDesktop } = useScreenSize();
  const { sessionData } = useSessionData();

  const organisationLogo = useMemo(() => {
    return sessionData?.logos?.logoMediumImage;
  }, [sessionData]);

  const [search, setSearch] = useState<string>('');

  const handleCartPress = useCallback(()=> {
    navigateTo(enumNavigationPath.basket);
  }, [navigateTo])

  const handlePressGoToCatalogue = useCallback(() => {
    navigateTo(enumNavigationPath.home);
  }, [navigateTo]);

  const renderDesktopHeader = useCallback(() => {
    if (!isDesktop) return;
    return (
      <DesktopHeader
        carrouselArray={carrouselData}
        search={search}
        organisationLogo={organisationLogo!}
        setSearch={setSearch}
        onGoCartPress={handleCartPress}
        onExplorePress={()=>{}}
      />
    )
  }, [carrouselData, organisationLogo, search, isDesktop,handleCartPress]);

  const renderMobileHeader = useCallback(() => {
    if (isDesktop) return;
    return (
      <MobileHeader
        search={search}
        organisationLogo={organisationLogo!}
        setSearch={setSearch}
        onGoCartPress={handleCartPress}
        handleExplorePress={handlePressGoToCatalogue}
      />
    )
  }, [organisationLogo, search, isDesktop,handleCartPress,handlePressGoToCatalogue]);


  return (<div className={styles.container}>
    {renderDesktopHeader()}
    {renderMobileHeader()}
    <div className={styles.productsContainer}>
      {productByCategories.map((category, i) => {
        return (
          <div
            key={i}
            className={styles.categoryContainer}
          >
            <div className={styles.categoryNamesRowStyle}>
              <p className={styles.categoryNameStyle}>
                {category.categoryName}
              </p>
              <p onClick={() => {
                onSelectedCategory(category);
                navigateTo(enumNavigationPath.carte);
              }} className={styles.seeMore}>
                Voir plus
              </p>
            </div>
            <div className={styles.categorySubContainer}>
              {category.produits.slice(isDesktop ? -4 : -2).map((product, j) => {
                const price =
                  product.productSellingPrice![
                    product.productSellingPrice?.length! - 1
                  ].price;
                const elementEx = basket.find((ele) => ele._id === product._id);
                const quantity = elementEx && elementEx.quantitySelected;
                return (
                  <ProductCard
                    key={j}
                    name={product.productName}
                    description={product.productDescription}
                    price={price}
                    currency={"Fcfa"}
                    uri={product.productImage}
                    onSharePress={() => { }}
                    onProductPress={() => {
                      onSelectedProduct(product);
                      onSelectedCategory(category);
                      navigateTo(enumNavigationPath.product);
                    }}
                    handleBasketIconPress={() => {
                      onAddProductInBasket(
                        basket,
                        {
                          ...product,
                          quantitySelected: 1,
                        },
                      );
                    }}
                    elementExist={!!elementEx}
                    onQuantityUp={() => {
                      onAddProductInBasket(
                        basket,
                        {
                          ...product,
                          quantitySelected: 1,
                        },
                      );
                    }}
                    onQuantityDown={() => onSelectedProductQuantityDown(basket, elementEx!)}
                    quantity={quantity}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  </div>);
};
