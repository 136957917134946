import React, { useCallback, useMemo } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import styles from './styles.module.css';
import { Input } from "../../Input";
import IconCart from '../../../assets/icons/svgs/basket.svg';
import explorer from '../../../assets/icons/svgs/explorer.svg';
import IconCartDark from '../../../assets/icons/svgs/basket-dark.svg';
import explorerDark from '../../../assets/icons/svgs/explorer-dark.svg';
import { DesktopHeadProps } from "./interfaces";

export const DesktopHeader: React.FC<DesktopHeadProps> = ({
    carrouselArray,
    search,
    organisationLogo,
    isIconDark = false,
    setSearch,
    onGoCartPress,
    onExplorePress
}) => {
    const explorerIcon = useMemo(() => !isIconDark ? explorer : explorerDark, [isIconDark])
    const cartIcon = useMemo(() => !isIconDark ? IconCart : IconCartDark, [isIconDark])

    const renderIndicator = useCallback((
        onClickHandler: any,
        isSelected: boolean,
        index: number,
        label: string
    ) => {
        if (isSelected) {
            return (
                <div
                    className={styles.activeIndicator}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    key={index}
                />
            );
        }
        return (
            <div
                className={styles.inActiveIndicator}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                key={index}
            />
        );
    }, []);

    const renderCustomNextArrow = useCallback((
        onClickHandler: any,
        hasNext: boolean,
        label: string,
    ) => {

        return (
            <label
                className={styles.rightIcon}
                onClick={onClickHandler}
            >
                {'>'}
            </label>
        );
    }, []);

    const renderCustomArrowPrev = useCallback((
        onClickHandler: any,
        hasNext: boolean,
        label: string,
    ) => {

        return (
            <label
                className={styles.leftIcon}
                onClick={onClickHandler}
            >
                {'<'}
            </label>
        );
    }, []);

    return (
        <div
            className={styles.container}
        >
            <div className={styles.headerContainer}>
                <img
                    src={organisationLogo}
                    alt="company brand"
                    className={styles.companyLogo}
                />

                <div className={styles.inputAndCardContainer}>
                    {search && setSearch && (
                        <Input
                            value={search}
                            onChangeText={setSearch}
                            placeholder="Recherche"
                        />
                    )}
                    <div className={styles.iconsWrapper}>
                        <img
                            src={explorerIcon}
                            alt="Explorer button"
                            className={styles.IconCart}
                            onClick={onExplorePress}
                        />
                        <img
                            src={cartIcon}
                            alt="Icon Cart"
                            className={styles.IconCart}
                            onClick={onGoCartPress}
                        />
                    </div>
                </div>
            </div>
{
            <Carousel
                showStatus={false}
                showThumbs={false}
                dynamicHeight={false}
                infiniteLoop
                autoPlay
                swipeable
                renderIndicator={renderIndicator}
                renderArrowNext={renderCustomNextArrow}
                renderArrowPrev={renderCustomArrowPrev}
            >
                {
                    carrouselArray?.map((el, i) => {
                        return (
                            <div
                                key={i}
                            >
                                <div
                                    style={{
                                        backgroundImage: `url(${el.uri})`
                                    }}
                                    className={styles.imgBackground}
                                />
                            </div>
                        )
                    })
                }
            </Carousel>}
        </div>
    )
}