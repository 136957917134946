import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Product, basketType, productsByCategories } from "../../types";
import { useSessionData } from "../../hooks";
import { groupProductsByCategories } from "../../functions";
import { 
    ADD_PRODUCT_IN_BASKET_REQUEST,
    GET_ALL_CATEGORIES_REQUEST, 
    GET_ALL_PRODUCTS_REQUEST, 
    SELECTED_CATEGORY_REQUEST, 
    SELECTED_PRODUCT_QUANTITY_DOWN_REQUEST, 
    SELECTED_PRODUCT_REQUEST,
} from "../../modules";

export const useController = ()=> {

    const dispatch = useDispatch();
    const {
      sessionData, 
    } = useSessionData();

    const [productByCategories, setProductByCategories] = useState<productsByCategories[]>([]);

    const allProducts = useSelector((state: any)=> state.ProductReducers.allProducts);
    const categories = useSelector((state: any)=> state.CategoriesReducers.allCategories);
    const selectedCategory = useSelector((state: any)=> state.CategoriesReducers.selectedCategory);
    const basket = useSelector((state: any) => state.BasketReducers.basket);

    useEffect(()=> {
        onGetCategories(sessionData?._id!);
        onGetProducts(sessionData?._id!);
    }, [sessionData]);

    useEffect(()=> {
        if(allProducts.length > 0 && categories.length > 0){
        const result = groupProductsByCategories(categories, allProducts);
        setProductByCategories(result);
        }
    }, [categories, allProducts]);

    const onGetProducts = useCallback((organisationID: string)=> {
        return dispatch({ type: GET_ALL_PRODUCTS_REQUEST, payload: organisationID });
    }, [dispatch]);

    const onGetCategories = useCallback((organisationID: string)=> {
        return dispatch({ type: GET_ALL_CATEGORIES_REQUEST, payload: organisationID });
    }, [dispatch]);

    const onSelectedCategory = useCallback((category: productsByCategories)=> {
        return dispatch({ type: SELECTED_CATEGORY_REQUEST, payload: category });
    }, [dispatch]);

    const onSelectedProduct = useCallback((product: Product)=> {
        return dispatch({ type: SELECTED_PRODUCT_REQUEST, payload: product });
    }, [dispatch]);

    const onSelectedProductQuantityDown = useCallback((
        basket: basketType[],
        item: basketType
    ) => {
        return dispatch({
            type: SELECTED_PRODUCT_QUANTITY_DOWN_REQUEST,
            payload: { basket, item },
        });
    }, [dispatch]);

    const onAddProductInBasket = useCallback((
        basket: basketType[],
        newItem: basketType,
    ) => {
        return dispatch({
            type: ADD_PRODUCT_IN_BASKET_REQUEST,
            payload: { basket, newItem },
        });
    }, [dispatch]);
    return {
        productByCategories,
        selectedCategory,
        onSelectedCategory,
        onSelectedProduct,
        basket,
        sessionData,
        onAddProductInBasket,
        onSelectedProductQuantityDown,
    }
}