import { RecapProps } from "./interfaces";
import styles from "./styles.module.css";
import RecapIconImg from "../../../../assets/svgImages/RecapIconImg.svg";

import { useCallback, useMemo } from "react";
import { formatNumberToPrice, formatTextMaxChars_15 } from "../../../../functions";

import { 
  Button, ScreenWrapper, 
} from "../../components";
import { useNavigation, useScreenSize } from "../../../../hooks";
import { enumNavigationPath } from "../../../../enums";
import LeftIcon from '../../assets/icons/svgs/left_2.svg';

export const RecapComponentTemplateA: React.FC<RecapProps> = ({
  basket,
  deliveryData,
}) => {

  const {
    isMobile,
    isDesktop,
  } = useScreenSize();
  const {
    navigateTo,
    goBack,
  } = useNavigation();

  const totalBasket = useMemo(() => {
    let total = 0;

    basket.map((el)=> {
      const price =
        el.productSellingPrice[el.productSellingPrice.length - 1].price;
      total += price * el.quantitySelected;
      el.extraSideDish?.map((supp) => {
        total += supp.price;
      });
    });

    return total;
  }, [basket]);

  const onButtonPress = useCallback(() => {
    navigateTo(enumNavigationPath.payment);
  }, [navigateTo]);

  const handleGoLeft = useCallback(() => {
    goBack();
  }, [goBack]);

  const renderdesktopLeftIcon = useCallback(()=> {
    if(!isDesktop) return;

    return (
      <img 
        src={LeftIcon}
        alt="left icon" 
        className={styles.desktopLeftIcon}
        onClick={handleGoLeft}
      />
    )
  }, [isDesktop, handleGoLeft]);

  const renderDetailsOrder = useCallback(()=> {
    return (
      <div className={styles.orderRecapContainer}>
        <div className={styles.leftContent}>
          <div className={styles.sectionTitle}>
            <label>
              Commandes
            </label>
          </div>

          <div>
            {
              basket.map((el, i)=> {
                return (
                  <p
                    key={i}
                    className={styles.orderItem}
                  >
                    {formatTextMaxChars_15(el.productName)} x{el.quantitySelected}
                  </p>
                )
              })
            }
          </div>

          <p className={styles.totalLabel}>
            Total à payer
          </p>
        </div>
        
        <div className={styles.rightContent}>
          <div className={styles.sectionTitle}>
            <label>
              Prix
            </label>
          </div>

          <div className={styles.rightContentSubContainer}>
            {
              basket.map((el, i)=> {
                const price = el.productSellingPrice[el.productSellingPrice.length - 1].price;
                return (
                  <p
                    key={i}
                    className={styles.orderItem}
                  >
                    {formatNumberToPrice(el.quantitySelected * price)} Fcfa
                  </p>
                )
              })
            }
          </div>

          <p className={styles.totalValue}>
            {formatNumberToPrice(totalBasket)} Fcfa
          </p>
        </div>
      </div>
    )
  }, [basket, totalBasket]);

  return (
    <ScreenWrapper
      onGoLeftPress={()=> {}}
    >
      <div className={styles.container}>
        
        {renderdesktopLeftIcon()}

        <p className={styles.titleText}>
          Recapitulatif
        </p>

        <div className={styles.illustrationContainer}>
          <img 
            src={RecapIconImg} 
            alt="Recap img"
            className={styles.illustrationImg}
          />
        </div>

        {renderDetailsOrder()}

        <div className={styles.sectionContainer}>
          <p className={styles.sectionTitle2}>
            Informations de livraison
          </p>
          <p className={styles.infosTxt}>
            {deliveryData.firstname} {deliveryData.lastname}
          </p>
          <p className={styles.infosTxt}>
            {deliveryData.phone}
          </p>
          <p className={styles.infosTxt}>
            {deliveryData.street}, {deliveryData.adress}, {deliveryData.city}, {deliveryData.country}
          </p>

          <div className={styles.buttonContainer}>
            <Button 
              buttonName="Valider"
              onButtonPress={onButtonPress}
              containerStyle={{    
                padding: (isMobile || isDesktop) ? 10 : 15,
                paddingLeft: (isMobile || isDesktop) ? 60 : 100,
                paddingRight: (isMobile || isDesktop) ? 60 : 100,
                fontSize: (isMobile || isDesktop) ? 14 : 20,
                marginTop: (isMobile || isDesktop) ? 0 : 20,
                marginBottom: (isMobile || isDesktop) ? 0 : 20,
              }}
            />
          </div>
        </div>
      </div>
    </ScreenWrapper>
  );
};
