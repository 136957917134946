import React, { useCallback } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import styles from './styles.module.css';
import {DesktopHeadProps} from './interfaces';
import { Input } from "../../Input";
import IconCart from '../../../assets/icons/svgs/cart.svg';

export const DesktopHead:React.FC<DesktopHeadProps> = ({
    carrouselArray,
    search,
    organisationLogo,
    setSearch,
    onGoCartPress,
})=> {

    const renderIndicator = useCallback((
        onClickHandler: any, 
        isSelected: boolean, 
        index: number, 
        label: string
    )=> {
        if (isSelected) {
            return (
                <div
                    className={styles.activeIndicator}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    key={index}
                />
            );
        }
        return (
            <div
                className={styles.inActiveIndicator}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                key={index}
            />
        );
    }, []);

    const renderCustomNextArrow = useCallback((
        onClickHandler: any, 
        hasNext: boolean, 
        label: string,
    ) => {
        
        return (
            <label
                className={styles.rightIcon}
                onClick={onClickHandler}
            >
                {'>'}
            </label>
        );
    }, []);

    const renderCustomArrowPrev = useCallback((
        onClickHandler: any, 
        hasNext: boolean, 
        label: string,
    ) => {
        
        return (
            <label
                className={styles.leftIcon}
                onClick={onClickHandler}
            >
                {'<'}
            </label>
        );
    }, []);

    return (
        <div
            className={styles.container}
        >
            <div className={styles.headerContainer}>
                <img 
                    src={organisationLogo} 
                    alt="company brand"
                    className={styles.companyLogo}
                />

                <div className={styles.inputAndCardContainer}>
                    <Input
                        value={search}
                        onChangeText={setSearch}
                        placeholder="Recherche"
                        placeholderColor='#fff'
                        customStyle={{
                            border: 'none',
                            backgroundColor: '#FFFFFF91',
                            color: '#fff',
                            marginTop: 10,
                            marginRight: 30
                        }}
                    />

                    <img 
                        src={IconCart} 
                        alt="Icon Cart"
                        className={styles.IconCart}
                        onClick={onGoCartPress}
                    />
                </div>
            </div>

            <Carousel
                showStatus={false}
                showThumbs={false}
                dynamicHeight={false}
                infiniteLoop
                autoPlay
                swipeable
                renderIndicator={renderIndicator}
                renderArrowNext={renderCustomNextArrow}
                renderArrowPrev={renderCustomArrowPrev}
            >
                {
                    carrouselArray.map((el, i)=> {
                        return (
                            <div
                                key={i}
                            >
                                <div
                                    style={{
                                        backgroundImage: `url(${el.uri})`
                                    }}
                                    className={styles.imgBackground}
                                />
                            </div>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}