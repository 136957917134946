import React from "react";

import {ScreenWrapperProps} from './interfaces';

import styles from './styles.module.css';
import { useScreenSize, useSessionData } from "../../../../hooks";

import IconCart from '../../assets/icons/svgs/cart.svg';
import Iconleft from '../../assets/icons/svgs/left.svg';

export const ScreenWrapper:React.FC<ScreenWrapperProps> = ({
    children,
    onGoLeftPress,
    onGoCartPress,
})=> {

    const {isDesktop} = useScreenSize();
    const { sessionData } = useSessionData();
    const organisationLogo = sessionData?.logos?.logoMediumImage;

    return (
        <div
            className={styles.container}
        >
            {
                !isDesktop && (
                    <div className={styles.headerContainer}>
                        <img 
                            src={organisationLogo} 
                            alt="company brand"
                            className={styles.companyLogo}
                        />

                        <div className={styles.iconsGroup}>
                            {
                                onGoLeftPress && (
                                    <img 
                                        src={Iconleft} 
                                        alt="Icon left"
                                        className={styles.IconLeft}
                                        onClick={onGoLeftPress}
                                    />
                                )
                            }
                            {
                                onGoCartPress && (
                                    <img 
                                        src={IconCart} 
                                        alt="Icon Cart"
                                        className={onGoLeftPress ? styles.IconCart : styles.IconCart_2_Position }
                                        onClick={onGoCartPress}
                                    />
                                )
                            }
                        </div>
                    </div>
                )
            }

            {children}
        </div>
    )
}
