import React, { useMemo } from "react";
import { useController } from "./useController";
import { 
  LivraisonComponentTemplateA, 
  LivraisonComponentTemplateB,
} from "../../templates";

export const Livraison: React.FC = () => {
  
  const {
    clientData,
    sessionData,
    onSetClientDataLivraison
  } = useController();

  const templateType = useMemo(()=> {
    return sessionData?.template!
  }, [sessionData]);

  const TEMPLATES:any = useMemo(() => {
    return {
      A: LivraisonComponentTemplateA,
      B: LivraisonComponentTemplateB,
    }
  }, []);

  const TemplateComponent = useMemo(() => {
    return TEMPLATES[templateType];
  }, [
    TEMPLATES,
    templateType
  ]);

  return (
    <TemplateComponent
      clientData={clientData}
      onSetClientDataLivraison={onSetClientDataLivraison}
    />
  );
};
