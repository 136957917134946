import React, { useCallback, useMemo } from "react";

import {
  ProductCard,
} from '../../components';

import { CarteProps } from "./interfaces";

import styles from "./styles.module.css";
import { enumNavigationPath } from "../../../../enums";
import { useNavigation, useScreenSize, useSessionData } from "../../../../hooks";

import CarteIcon from '../../assets/icons/svgs/basket.svg';
import Explorer from '../../assets/icons/svgs/explorer.svg';
import Explorer_Dark from '../../assets/icons/svgs/explorer-dark.svg';
import Basket_Gold from '../../assets/icons/svgs/basket-gold.svg';
import Back_Button from '../../assets/icons/svgs/back-button.svg';

export const CarteComponentTemplateB: React.FC<CarteProps> = ({
  selectedCategory,
  productByCategories,
  onSelectedProduct,
  onSelectedCategory,
  onAddProductInBasket,
  onSelectedProductQuantityDown,
  basket
}) => {

  const { isDesktop } = useScreenSize();
  const { sessionData } = useSessionData();
  const { navigateTo } = useNavigation()
  const organisationLogo = useMemo(() => {
    return sessionData?.logos?.logoMediumImage;
  }, [sessionData]);

  const renderCategoryImgContainer = useCallback(() => {
    if (isDesktop) return;

    return (
      <div
        className={styles.categoryContentContainer}
      >
        <div
          className={styles.categoryContainer}
          style={{
            backgroundImage: `url(${selectedCategory?.categoryImage})`
          }}
        >
          <p className={styles.categoriesNameTxt}>
            {selectedCategory?.categoryName}
          </p>
          <div
            className={styles.categoryDarkScreen}
          />
        </div>
      </div>
    )
  }, [selectedCategory, isDesktop]);

  const renderSelectCategoryComponent = useCallback(() => {
    return (
      <div className={styles.listCategoriesContainer}>
        <p>Catégories</p>
        <div className={styles.categorySelectContainer}>
          {
            productByCategories.map((category, i) => {
              return (
                <div
                  key={i}
                  className={
                    category._id === selectedCategory?._id ?
                      styles.categorySelectItemActiveContainer : styles.categorySelectItemInActiveContainer
                  }
                  onClick={() => {
                    onSelectedCategory(category);
                  }}
                >
                  {category.categoryName}
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }, [onSelectedCategory, productByCategories, selectedCategory])

  const handleGotoHome = useCallback(() => {
    navigateTo(enumNavigationPath.home)
  }, [navigateTo])

  const handleGoToBasket = useCallback(() => {
    navigateTo(enumNavigationPath.basket)
  }, [navigateTo])

  const renderDesktopHeader = useCallback(() => {
    if (!isDesktop) return;

    return (
      <div
        className={styles.desktopCategoryContentContainer}
        style={{
          backgroundImage: `url(${selectedCategory?.categoryImage})`
        }}
      >
        <div
          className={styles.desktopDarkScreen}
        />

        <div className={styles.desktopCategoryHeadContainer}>
          <img
            onClick={handleGotoHome}
            src={organisationLogo}
            alt="logo"
            className={styles.logoStyle}
          />
          <div className={styles.iconsWrapper}>
            <div className={styles.explorerWrapper}>
              <img
                src={Explorer}
                alt="Explorer button"
                className={styles.IconCart}
                onClick={handleGotoHome}
              />
            </div>
            <div className={styles.basketWrapper}>
              <img
                src={CarteIcon}
                alt="Icon Cart"
                className={styles.IconCart}
                onClick={handleGoToBasket}
              />
            </div>
          </div>

        </div>

        <p
          className={styles.desktopCategoryName}
        >
          {selectedCategory?.categoryName}
        </p>
      </div>
    )
  }, [
    isDesktop,
    selectedCategory,
    handleGotoHome,
    organisationLogo,
    handleGoToBasket
  ]);

  const renderMobileHeader = useCallback(() => {
    if (isDesktop) return

    return (
      <div className={styles.mobileHeaderContainer}>
        <div className={styles.basketWrapper}>
          <img
            src={Back_Button}
            alt="Retour"
            className={styles.IconCart}
            onClick={handleGotoHome}
          />
        </div>
        <div className={styles.iconsWrapper}>

          <div className={styles.explorerWrapper}>
            <img
              src={Explorer_Dark}
              alt="Explorer button"
              className={styles.IconCart}
              onClick={handleGotoHome}
            />
          </div>
          <div className={styles.basketWrapper}>
            <img
              src={Basket_Gold}
              alt="Icon Cart"
              className={styles.IconCart}
              onClick={handleGoToBasket} // todo implement
            />
          </div>
        </div>
      </div>
    )
  }, [handleGoToBasket, handleGotoHome, isDesktop])

  return (
    <div className={styles.container}>

      {renderDesktopHeader()}
      {renderMobileHeader()}
      {renderCategoryImgContainer()}
      {renderSelectCategoryComponent()}

      <div className={styles.productsContainer}>
        {selectedCategory?.produits.map((product, i) => {
          const price =
            product.productSellingPrice![
              product.productSellingPrice?.length! - 1
            ].price;
          const elementEx = basket.find((ele) => ele._id === product._id);
          const quantity = elementEx && elementEx.quantitySelected;
          return (
            <ProductCard
              key={i}
              name={product.productName}
              price={price}
              currency={"Fcfa"}
              uri={product.productImage}
              description={product.productDescription}
              onSharePress={() => { }}
              onProductPress={() => {
                onSelectedProduct(product);
                navigateTo(enumNavigationPath.product);
              }}
              handleBasketIconPress={() => {
                onAddProductInBasket(
                  basket,
                  {
                    ...product,
                    quantitySelected: 1,
                  },
                );
              }}
              elementExist={!!elementEx}
              onQuantityUp={() => {
                onAddProductInBasket(
                  basket,
                  {
                    ...product,
                    quantitySelected: 1,
                  },
                );
              }}
              onQuantityDown={() => onSelectedProductQuantityDown(basket, elementEx!)}
              quantity={quantity}
            />
          );
        })}
      </div>
    </div>
  );
};
