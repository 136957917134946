import React, { useMemo } from "react";

import {ProductCardProps} from './interfaces';

import styles from './styles.module.css';

import { 
    formatNumberToPrice, 
    formatTextMaxChars_10,
    formatTextMaxChars_15,
} from "../../../../../functions";

import PlusIconSvg from '../../../assets/icons/svgs/plus.svg';
import { useScreenSize } from "../../../../../hooks";

export const ProductCard:React.FC<ProductCardProps> = ({
    uri,
    name,
    price,
    currency,
    description,
    onSharePress,
    onProductPress,
})=> {

    const {
        isMobile
    } = useScreenSize();

    const productName = useMemo(()=> {
        if(isMobile){
            return formatTextMaxChars_10(name);
        } else {
            return formatTextMaxChars_15(name);
        }
    }, [isMobile, name]);

    return (
        <div
            style={{backgroundImage: `url(${uri})`}}
            className={styles.productItemContainer}
        >
            <div 
                className={styles.productItemBodyContainer}
            >
                <p className={styles.productItemPrice}
                >{formatNumberToPrice(price)} {currency}</p>

                <p className={styles.productItemName}
                >{productName}</p>

                <img 
                    src={PlusIconSvg}
                    className={styles.plusIconSvg}
                    alt="plus icon"
                    onClick={onProductPress}
                />
            </div>
        </div>
    )
}