import React, { useState } from "react";

import {BannerSliderProps} from './interfaces';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import styles from './styles.module.css';

export const BannerSlider: React.FC<BannerSliderProps> = ({
    bannerSliderData
}) => {

    const [itemCenterIndex, setItemCenterIndex] = useState<number>(0);

    const settings = {
        infinite: true,
        swipeToSlide: true,
        variableWidth: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 300,
        dotsClass: "slick-dots slick-thumb",
        afterChange: function(index: number) {
            setItemCenterIndex(index);
        },
        customPaging: function(i: number) {
            return (
                <div 
                    className={
                        i === itemCenterIndex 
                        ? styles.activeCustomDot : styles.customDot
                    } 
                />
            );
        },
    };
    
    return (
        <div className={styles.container}>
            <Slider {...settings}>
                {
                    bannerSliderData.map((item, i)=> {
                        return (
                            <div
                                key={i}
                            >
                                <div
                                    className={
                                        i === itemCenterIndex ? 
                                        styles.activeCustomSlide : styles.customSlide
                                    }
                                >
                                    <img 
                                        className={styles.imgStles}
                                        src={item.bgImgUri}
                                        alt={item.subTitle}
                                    />
                                    <div className={styles.detailsContainer}>
                                        <p className={styles.itemTitle}>
                                            {item.title}
                                        </p>
                                        <p className={styles.itemSubTitle}>
                                            {item.subTitle}
                                        </p>

                                        <div className={styles.itemButton}>
                                            {item.buttonName}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Slider>
        </div>
    )
}
