import React, { useCallback, useMemo } from "react";

import styles from './styles.module.css';
import {MobilAndTableHeadProps} from './interfaces';
import { useNavigation, useSessionData } from "../../../../../hooks";

import IconLeft from '../../../assets/icons/svgs/left.svg';

export const MobilAndTableHead:React.FC<MobilAndTableHeadProps> = ()=> {

    const { sessionData } = useSessionData();
    const { goBack } = useNavigation();
    const organisationLogo = useMemo(()=> {
        return sessionData?.logos?.logoMediumImage;
    }, [sessionData]);

    const handlePressLeft = useCallback(()=> {
        goBack();
    }, [goBack]);

    return (
        <div
            className={styles.container}
        >
            <div className={styles.companyLogoContainer}>
                <img 
                    src={organisationLogo} 
                    alt="company brand"
                    className={styles.companyLogo}
                />
            </div>

            <img 
                src={IconLeft} 
                alt="left icon"
                className={styles.leftIcon}
                onClick={handlePressLeft}
            />
        </div>
    )
}