import React, { useCallback } from "react";
import { Button, ScreenWrapper } from "../../components";

import { SuccessProps } from "./interfaces";

import styles from "./styles.module.css";

import OrderChecked from "../../assets/illustrations/svgs/orderChecked.svg";
import DownloadIcon from "../../assets/icons/svgs/download.svg";
import factureImg from "../../assets/images/facture.png";

export const SuccessComponentTemplateA: React.FC<SuccessProps> = ({
  onCleanBasket,
  onDownloadPress,
}) => {
  const handleDownloadFacture = useCallback(() => {
    onDownloadPress();
  }, [onDownloadPress]);

  return (
    <ScreenWrapper>
      <div className={styles.container}>
        <p className={styles.titlePage}>
          Commande validée
        </p>

        <img 
          src={OrderChecked} 
          alt="commande check"
          className={styles.OrderChecked}
        />

        <div className={styles.grp_1}>
          <p className={styles.txt1}>
            Merci pour votre commande
          </p>
          <p className={styles.txt2}>
            Vous recevrez un mail sur l’état d’avancement de votre commande et sa
            date de livraison
          </p>
        </div>

        <img
          src={factureImg}
          alt="miniature facture"
          className={styles.factureImg}
        />
        <div className={styles.downloadContainer}>
          <label onClick={handleDownloadFacture}>
            Télécharger la facture
          </label>
          <img
            src={DownloadIcon}
            alt="icon download"
            width={30}
            onClick={handleDownloadFacture}
          />
        </div>

        <Button
          buttonName="Retour à l'acceuil"
          onButtonPress={onCleanBasket}
        />
      </div>
    </ScreenWrapper>
  );
};
