import React, { useEffect, useMemo, useState } from "react";

import {
    collection,
    db,
    onSnapshot,
    query,
    where,
} from '../../database';
import { CollectionDbEnum, OrganisationTypeEnum, Sale } from "../../types";
import { OrderTrackingComponentB } from "../../templates";
import { useSessionData } from "../../hooks";

export const OrderTracking: React.FC = () => {

    const { sessionData } = useSessionData();

    const [sale, setSale] = useState<Sale | null>(null);
    const [orderNumber, setOrderNumber] = useState<string>('');

    const templateType = useMemo(()=> {
        return sessionData?.template!
      }, [sessionData]);

    const TEMPLATES:any = useMemo(() => {
        return {
            A: OrderTrackingComponentB,
            B: OrderTrackingComponentB,
        }
    }, []);

    const TemplateComponent = useMemo(() => {
        return TEMPLATES[templateType];
    }, [
        TEMPLATES,
        templateType
    ]);

    useEffect(() => {
        if (orderNumber !== '') {
            const q = query(collection(db, CollectionDbEnum.Sales),
                where("_organisationType", "==", OrganisationTypeEnum.Shop),
                where("orderNumber", "==", orderNumber),
            );
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    setSale(doc.data() as Sale);
                });

            });
        }
    }, [orderNumber]);

    return (
        <TemplateComponent
            sale={sale}
            onGetOrderNumber={setOrderNumber}
        />
    )
};