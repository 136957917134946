import React, { useCallback, useMemo, useState } from "react";

import {
  ScreenWrapper,
  ProductCard,
  Input,
} from '../../components';

import { CarteProps } from "./interfaces";

import styles from "./styles.module.css";
import { enumNavigationPath } from "../../../../enums";
import { useScreenSize, useSessionData } from "../../../../hooks";

import CarteIcon from '../../assets/icons/svgs/cart.svg';

export const CarteComponentTemplateA: React.FC<CarteProps> = ({
  selectedCategory,
  productByCategories,
  onSelectedProduct,
  onSelectedCategory,
  navigateTo
}) => {

  const {isDesktop} = useScreenSize();
  const { sessionData } = useSessionData();
  const organisationLogo = useMemo(()=> {
    return sessionData?.logos?.logoMediumImage;
  }, [sessionData]);

  const [search, setSearch] = useState<string>('');

  const renderCategoryImgContainer = useCallback(()=> {
    if(isDesktop) return;

    return (
      <div
        className={styles.categoryContentContainer}
      >
        <div
          className={styles.categoryContainer}
          style={{
            backgroundImage: `url(${selectedCategory?.categoryImage})`
          }}
        >
          <p className={styles.categoriesNameTxt}>
            {selectedCategory?.categoryName}
          </p>
          <div 
            className={styles.categoryDarkScreen}
          />
        </div>
      </div>
    )
  }, [selectedCategory, isDesktop]);

  const renderSelectCategoryComponent = useCallback(()=> {
    return (
      <div className={styles.categorySelectContainer}>
        {
          productByCategories.map((category, i)=> {
            return (
              <div
                key={i}
                className={
                  category._id === selectedCategory?._id ?
                  styles.categorySelectItemActiveContainer : styles.categorySelectItemInActiveContainer
                }
                onClick={()=> {
                  onSelectedCategory(category);
                }}
              >
                {category.categoryName}
              </div>
            )
          })
        }
      </div>
    )
  }, [onSelectedCategory, productByCategories, selectedCategory])

  const renderInpput = useCallback(()=> {
    if(isDesktop) return;

    return (
      <div className={styles.inputContainer}>
        <Input 
          value={search}
          onChangeText={setSearch}
          placeholder="Recherche"
        />
      </div>
    )
  }, [isDesktop, search]);

  const renderDesktopHeader = useCallback(()=> {
    if(!isDesktop) return;

    return (
      <div
        className={styles.desktopCategoryContentContainer}
        style={{
          backgroundImage: `url(${selectedCategory?.categoryImage})`
        }}
      >
        <div
          className={styles.desktopDarkScreen}
        />

        <div className={styles.desktopCategoryHeadContainer}>
          <img 
            src={organisationLogo}
            alt="logo"
            className={styles.logoStyle}
          />

          <div className={styles.inputAndCartContainer}>
            <Input 
              value={search}
              onChangeText={setSearch}
              placeholder="Recherche"
              placeholderColor='#fff'
              customStyle={{
                border: 'none',
                backgroundColor: '#FFFFFF91',
                color: '#fff',
                marginTop: 10,
                marginRight: 30
              }}
            />

            <img 
              src={CarteIcon}
              alt="cart"
              className={styles.carteStyle}
            />
          </div>
        </div>

        <p
          className={styles.desktopCategoryName}
        >
          {selectedCategory?.categoryName}
        </p>
      </div>
    )
  }, [
    search, 
    selectedCategory, 
    organisationLogo, 
    isDesktop,
  ]);

  return (
    <ScreenWrapper
      onGoCartPress={()=> {}}
      onGoLeftPress={()=> {}}
    >
      <div className={styles.container}>
        
        {renderDesktopHeader()}
        {renderCategoryImgContainer()}
        {renderSelectCategoryComponent()}
        {renderInpput()}

        <div className={styles.productsContainer}>
          {selectedCategory?.produits.map((product, i) => {
            const price =
              product.productSellingPrice![
                product.productSellingPrice?.length! - 1
              ].price;
            return (
              <ProductCard
                key={i}
                name={product.productName}
                price={price}
                currency={"Fcfa"}
                uri={product.productImage}
                description="description du produit ici shbkb"
                onSharePress={()=> {}}
                onProductPress={() => {
                  onSelectedProduct(product);
                  navigateTo(enumNavigationPath.product);
                }}
              />
            );
          })}
        </div>
      </div>
    </ScreenWrapper>
  );
};
