import React, { useState } from "react";

import {BrandActivitySliderprops} from './interfaces';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import styles from './styles.module.css';

export const BrandActivitySlider: React.FC<BrandActivitySliderprops> = ({
    activities
}) => {

    const [itemCenterIndex, setItemCenterIndex] = useState<number>(0);

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        swipeToSlide: true,
        variableWidth: true,
        speed: 300,
        afterChange: function(index: number) {
            // console.log(
            //     `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
            // );
            setItemCenterIndex(index);
        }
    };
    
    return (
        <div className={styles.container}>
            <Slider {...settings}>
                {
                    activities.map((item, i)=> {
                        return (
                            <div
                                key={i}
                            >
                                <div
                                    className={
                                        i === itemCenterIndex ? 
                                        styles.activeCustomSlide : styles.customSlide
                                    }
                                >
                                    <img 
                                        src={item.uri} 
                                        alt="image brand"
                                        className={styles.itemImgStyle}
                                    />
                                </div>
                            </div>
                        )
                    })
                }
            </Slider>
            
            <div
                className={styles.overlayCircle}
            />
        </div>
    )
}
