import React from "react";

import {ButtonProps} from './interfaces';

import styles from './styles.module.css';

export const Button:React.FC<ButtonProps> = ({
    buttonName,
    onButtonPress,
    containerStyle
})=> {

    return (
        <div
            onClick={onButtonPress}
            className={styles.container}
            style={containerStyle}
        >
            {buttonName}
        </div>
    )
}