import React from "react"
import styles from './styles.module.css';

import IconCart from '../../../assets/icons/svgs/basket-dark.svg';
import explorer from '../../../assets/icons/svgs/explorer-dark.svg';
import { MobieHeaderProps } from "./interfaces";
import { Input } from "../../Input";
import { InputStyle } from "../../Input/interfaces";


export const MobileHeader: React.FC<MobieHeaderProps> = ({
    search,
    organisationLogo,
    setSearch,
    onGoCartPress,
    handleExplorePress
}) => {
    return (<div className={styles.container}>
        <div className={styles.iconsWrapper}>
            <div className={styles.explorerWrapper}>
                <img
                    src={explorer}
                    alt="Explorer button"
                    className={styles.IconCart}
                    onClick={handleExplorePress}
                />
            </div>
            <div className={styles.basketWrapper}>
                <img
                    src={IconCart}
                    alt="Icon Cart"
                    className={styles.IconCart}
                    onClick={onGoCartPress}
                />
            </div>
        </div>
        <div className={styles.logoNItemWrapper}>
            <div className={styles.logoWrapper}>
                <img
                    src={organisationLogo}
                    alt="company brand"
                    className={styles.companyLogo}
                />
            </div>
            {search&& setSearch && <div className={styles.inputWrapper}>
                <Input
                    inputStyle={InputStyle.filled}
                    value={search}
                    onChangeText={setSearch}
                    placeholder="Recherche"


                />
            </div>}

        </div>
    </div>)
}