import React from "react";
import styles from './styles.module.css'

import { ProductCard } from "../ProductCard";
import { ShopCardProps } from "./interfaces";
import { formatTextMaxChars_27 } from "../../../../functions";

export const ShopCard: React.FC<ShopCardProps> = ({ 
    cardColor, 
    products, 
    shopName="...", 
    backgroundImg,
}) => {

    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>
                <h3>
                    {formatTextMaxChars_27(shopName)}
                </h3>

                <img
                    className={styles.cardImg}
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.517)' }}
                    src={backgroundImg}
                    alt="card_Image"
                />

                <div className={styles.overlay} />
            </div>

            <div
                style={{ backgroundColor: cardColor }}
                className={styles.cardSubcontainer}
            >
                <div className={styles.subContainerRow}>
                    <div>Nos Produits</div>
                    <div>Voir plus</div>
                </div>

                <div className={styles.productsWrapper}>
                    {
                        products?.map((product, index) => {
                            return (
                                <ProductCard 
                                    key={index} 
                                    name={product.name}
                                    productImg={product.productImg}
                                    price={product.price}
                                    currency={product?.currency}
                                />
                            )
                        })
                    }
                </div>
                {/* <div>
                    <p>Shop informations</p>
                    <p>Shop informations</p>
                    <p>Shop informations</p>
                </div> */}
            </div>
        </div>
    )
}