import { actionsShopSession, shopSessionInitialState } from "../../types";
import * as actions from "./actions";

const initialState:shopSessionInitialState = {
    shopSession: null,
    shopSessionErrorState: false
}

export default function ShopSessionReducers(
    state:shopSessionInitialState = initialState, 
    {type, payload}: actionsShopSession
){
    switch(type) {
        case actions.GET_SESSION_DATA_REQUEST: 
            return {
                ...state,
            }
        case actions.GET_SESSION_DATA_SUCCESS:
            return {
                ...state,
                shopSession: payload,
            }
        case actions.GET_SESSION_DATA_FAILURE:
            return {
                ...state,
                shopSessionErrorState: payload
            }
        default:
            return state
    }
}