import React from 'react';
import logo from './logo.svg';
import './App.css';

import {
  Navigations,
} from './navigations'; 
import {
  store,
  persistor,
} from './modules';

import { PersistGate } from 'redux-persist/integration/react';
import {Provider} from 'react-redux';

function App() {
  return (
    <Provider store={store}>
      <PersistGate 
        loading={null} 
        persistor={persistor}
      >
        <Navigations />
      </PersistGate>
    </Provider>
  );
}

export default App;
