import React, { useMemo } from "react";

import { useController } from "./useController";
import { useNavigation } from "../../hooks";
import { CarteComponentTemplateA, CarteComponentTemplateB } from "../../templates";

export const Carte:React.FC = ()=> {

  const {
    selectedCategory,
    productByCategories,
    onSelectedCategory,
    onSelectedProduct,
    basket,
    onAddProductInBasket,
    onSelectedProductQuantityDown,
    sessionData,
  } = useController();
  const { navigateTo } = useNavigation();

  const templateType = useMemo(()=> {
    return sessionData?.template!
  }, [sessionData]);

  const TEMPLATES:any = useMemo(()=> {
    return {
      A: CarteComponentTemplateA,
      B:CarteComponentTemplateB
    }
  }, []);

  const TemplateComponent = useMemo(()=> {
    return TEMPLATES[templateType];
  }, [
    TEMPLATES, 
    templateType
  ]);

  return (
    <TemplateComponent 
      selectedCategory={selectedCategory}
      productByCategories={productByCategories}
      onSelectedCategory={onSelectedCategory}
      onSelectedProduct={onSelectedProduct} 
      navigateTo={navigateTo}
      onAddProductInBasket={onAddProductInBasket}
      onSelectedProductQuantityDown={onSelectedProductQuantityDown}
      basket={basket}    
    />
  )
}
