import { Category, Product, productsByCategories } from "../types";

export const groupProductsByCategories = (
    categories: Category[], 
    products: Product[]
): productsByCategories[] => {
    const groupedProductsByCategories: productsByCategories[] = [];
  
    categories.forEach((category) => {
      const categoryObj = {
        ...category,
        produits: [] as Product[],
      };
  
      products.forEach((product) => {
        if (product._categoryID === category._id && product.isActive === true) {
          categoryObj.produits.push(product);
        }
      });
  
      groupedProductsByCategories.push(categoryObj);
    });
  
    return groupedProductsByCategories;
}
  