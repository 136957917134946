import React from "react";
import { Button } from "../../Button";
import { formatNumberToPrice } from "../../../../../functions";
import styles from "./styles.module.css";
import { FloatingRecapCardProps } from "./interface";
import { useScreenSize } from "../../../../../hooks";


export const FloatingRecapCard: React.FC<FloatingRecapCardProps> = ({ totalBasket, onButtonPress }) => {
    const { isDesktop } = useScreenSize()
    if (!isDesktop) return null;

    return (
        <div className={styles.recapContainer}>
            <p>A payer</p>
            <div className={styles.recapFloatingCardItemRow}>
                <p>Sous total</p>
                <p>{formatNumberToPrice(totalBasket)} Fcfa</p>
            </div>
            <div className={styles.recapFloatingCardItemRow}>
                <p>Frais de livraison estimée</p>
                <p>Offerts</p>
            </div>
            <div className={styles.totalItemRow}>
                <p>Total</p>
                <p>{formatNumberToPrice(totalBasket)} Fcfa</p>
            </div>
            <Button
                buttonName="Payer"
                onButtonPress={onButtonPress}
                containerStyle={{
                    marginTop: '5%',
                    alignSelf: 'center',
                    padding: 0,
                    width: '40%',
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                    height: 50,
                    backgroundColor: '#F6D18D',
                    borderRadius: 10

                }}
            />
        </div>
    )
}