import React from "react";
import { ProductCardProps } from "./interfaces";
import styles from './styles.module.css'
import { formatNumberToPrice } from "../../../../functions";
export const ProductCard: React.FC<ProductCardProps> = ({ name, productImg, price, currency = 'fcfa' }) => {

    return (
        <div className={styles.cardContainer}>
            <img
                className={styles.cardImage}
                src={productImg}
                alt="product_card_img" />
            <div>
                <p>{name}</p>
                <p>{`${formatNumberToPrice(price)} ${currency}`}</p>
            </div>
        </div>
    )
}