import { useSelector } from "react-redux";
import { basketType } from "../../types";
import { useSessionData } from "../../hooks";

export const useController = ()=> {

    const { sessionData } = useSessionData();

    const basket: basketType[] = useSelector(
        (state: any) => state.BasketReducers.basket
    );
    
    const deliveryData = useSelector(
        (state: any) => state.LivraisonReducers.clientData
    );

    return {
        basket,
        sessionData,
        deliveryData,
    }
}