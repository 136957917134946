import React, { useCallback, useMemo } from "react";

import { ProductProps } from "./interfaces";

import styles from "./styles.module.css";
import { Button, ProductCard, QuantityActionButtons } from "../../components";
import { useNavigation, useScreenSize } from "../../../../hooks";
import { enumNavigationPath } from "../../../../enums";

import { formatNumberToPrice } from "../../../../functions";
import { Product } from "../../../../types";
import IconCart from '../../assets/icons/svgs/cart.svg';
import IconLeft from '../../assets/icons/svgs/left.svg';
import IconCart_2 from '../../assets/icons/svgs/cart_2.svg';
import IconLeft_2 from '../../assets/icons/svgs/left_2.svg';

export const ProductComponentTemplateA: React.FC<ProductProps> = ({
  selectedProduct,
  basket,
  selectedCategory,
  onSelectedProductQuantityUp,
  onSelectedProductQuantityDown,
  onAddProductInBasket,
  onSelectedProduct,
}) => {
  
  const {navigateTo, goBack} = useNavigation();
  const {isDesktop} = useScreenSize();

  const elementExist = useMemo(()=> {
    return basket.find((ele) => ele._id === selectedProduct._id)
  }, [basket, selectedProduct]);

  const price = useMemo(()=> {
    const priceTmp = selectedProduct?.productSellingPrice![
      selectedProduct?.productSellingPrice?.length! - 1
    ].price;

    if(elementExist) {
      return priceTmp * elementExist.quantitySelected;
    }

    return priceTmp!
  }, [
    selectedProduct, 
    elementExist, 
    basket,
  ]);

  const handleGoBack = useCallback(()=> {
    goBack();
  }, [goBack]);

  const onAddQuantityProduct = useCallback(() => {
    if (!elementExist) {
      onAddProductInBasket(
        basket,
        {
          ...selectedProduct,
          quantitySelected: 1,
        },
      );
    } else {
      onSelectedProductQuantityUp(basket, elementExist);
    }
  }, [
    basket, 
    onAddProductInBasket, 
    onSelectedProductQuantityUp, 
    selectedProduct,
    elementExist
  ]);

  const onDeleteQuantityProduct = useCallback(() => {
    if(elementExist){
      onSelectedProductQuantityDown(basket, elementExist);
    }
  }, [
    basket, 
    onSelectedProductQuantityDown, 
    elementExist
  ]);

  const handleCartPress = useCallback(()=> {
    navigateTo(enumNavigationPath.basket);
  }, [navigateTo]);

  const renderQuantityActionButtons = useCallback(()=> {
    const quantity = elementExist && elementExist.quantitySelected;

    return (
      <QuantityActionButtons
        quantity={quantity!}
        onAddQuantityProduct={onAddQuantityProduct}
        onDeleteQuantityProduct={onDeleteQuantityProduct}
      />
    )
  }, [
    elementExist, 
    onAddQuantityProduct, 
    onDeleteQuantityProduct,
  ]);

  const renderButtonAddOrQuantityUpdateButton = useCallback(()=> {
    return (
      <div className={styles.actionButtonsGroupsContainer}>
        {
          elementExist ? (
            renderQuantityActionButtons()
          ) : (
            <div
              className={styles.buttoncontainer}
            >
              <Button 
                buttonName="Ajouter au panier"
                onButtonPress={onAddQuantityProduct}
                containerStyle={{
                  width: '80%'
                }}
              />
            </div>
          )
        }
      </div>
    )
  }, [elementExist, onAddQuantityProduct, renderQuantityActionButtons]);

  const renderMobilAndTabletContent = useCallback(()=> {
    if(isDesktop) return;

    return (
      <>
        <div
          style={{
            backgroundImage: `url(${selectedProduct.productImage})`
          }}
          className={styles.productImg}
        >
          <img 
            src={IconLeft}
            alt="Icon Left"
            className={styles.iconLeft}
            onClick={handleGoBack}
          />
        </div>

        <div
          className={styles.productDetailsContainer}
        >
          <div className={styles.separotorcontainer}>
            <div className={styles.separotor} />
          </div>

          <img 
            src={IconCart}
            alt="cart icon" 
            className={styles.iconCart}
            onClick={handleCartPress}
          />

          <p className={styles.price}>
            {formatNumberToPrice(price)} fcfa
          </p>

          <p className={styles.productName}>
            {selectedProduct.productName}
          </p>

          <p className={styles.productDescription}>
            {selectedProduct.productDescription}
          </p>        
        </div>

        {renderButtonAddOrQuantityUpdateButton()}
      </>
    )
  }, [
    handleGoBack,
    isDesktop, 
    handleCartPress, 
    price, 
    selectedProduct, 
    renderButtonAddOrQuantityUpdateButton,
  ]);

  const renderDesktopContent = useCallback(()=> {
    if(!isDesktop) return;

    return (
      <>
        <div className={styles.desktopHeadContainer}>
          <img 
            src={IconLeft_2}
            alt="Icon Left"
            className={styles.iconLeftDesktop}
            onClick={handleGoBack}
          />

          <img 
            src={IconCart_2}
            alt="Icon cart"
            className={styles.iconCartDesktop}
            onClick={handleCartPress}
          />
        </div>

        <div className={styles.desktopProductDetailsContainer}>
          <img 
            className={styles.productDesktopImg}
            src={selectedProduct.productImage}
            alt="product image"
          />

          <div className={styles.productDestopDetailSubContainer}>
            <div className={styles.productNameDesktop}>
              {selectedProduct.productName}
            </div>

            <div className={styles.productDestopDetailSubContainerContent}>
              <p className={styles.productDesktopDescription}>
                {selectedProduct.productDescription}
              </p>

              <p className={styles.productDesktopPrice}>
                {formatNumberToPrice(price)} Fcfa
              </p>

              {renderButtonAddOrQuantityUpdateButton()}
            </div>
          </div>
        </div>
      </>
    )
  }, [
    handleGoBack,
    price,
    selectedProduct,
    isDesktop,
    renderButtonAddOrQuantityUpdateButton,
    handleCartPress,
  ]);

  const renderSuggestionProducts = useCallback(()=> {
    return (
      <div className={styles.suggestionProductContainer}>
        <p className={styles.suggestionTitle}>
          Plus d'articles à découvrir
        </p>

        <div className={styles.suggestionProductSubContainer}>
          {
            selectedCategory?.produits?.map((product: Product, i: number)=> {
              const productPrice = product.productSellingPrice[product.productSellingPrice.length - 1].price;
              return (
                <div
                  key={i}
                  className={styles.suggestionProductItemContainer}
                >
                  <ProductCard 
                    uri={product.productImage}
                    name={product.productName}
                    price={Number(productPrice)}
                    currency={'fcfa'}
                    description={product.productDescription}
                    onSharePress={()=> {}}
                    onProductPress={()=> {
                      onSelectedProduct(product);
                      navigateTo(enumNavigationPath.product);
                    }}
                  />
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }, [
    navigateTo, 
    onSelectedProduct, 
    selectedCategory
  ]);

  return (
    <div className={styles.container}>

      {renderMobilAndTabletContent()}

      {renderDesktopContent()}

      {renderSuggestionProducts()}

    </div>
  );
};
