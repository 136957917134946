import React from "react";

import {BasketProductCardProps} from './interfaces';

import styles from './styles.module.css';

import { 
    formatNumberToPrice, 
    formatTextMaxChars_15, 
    formatTextMaxChars_50,
} from "../../../../../functions";
import { QuantityActionButtons } from "../QuantityActionButtons";

import TrashIcon from '../../../assets/icons/svgs/trash.svg';

export const BasketProductCard:React.FC<BasketProductCardProps> = ({
    uri,
    name,
    price,
    currency,
    description,
    quantity,
    onDeleteProduct,
    onQuantityDown,
    onQuantityUp,
})=> {

    return (
        <div
            className={styles.productItemContainer}
        >
            <div className={styles.leftContentContainer}>
                <img 
                    src={uri}
                    alt="product image"
                    className={styles.productImage}
                />

                <div className={styles.productItemPrice}>
                    {formatNumberToPrice(price * quantity)} {currency}
                </div>
            </div>

            <div 
                className={styles.productItemBodyContainer}
            >
                <div className={styles.productInfosContainer}>
                    <div>
                        <p className={styles.productItemName}
                        >{formatTextMaxChars_15(name)}</p>

                        <p className={styles.productItemDescription}>
                            {formatTextMaxChars_50(description)}
                        </p>
                    </div>
                </div>

                <QuantityActionButtons 
                    quantity={quantity}
                    onAddQuantityProduct={onQuantityUp}
                    onDeleteQuantityProduct={onQuantityDown}
                />
            </div>
            
            <img 
                src={TrashIcon}
                alt="trash icon"
                className={styles.trashIcon}
                onClick={onDeleteProduct}
            />
        </div>
    )
}