import React, { useEffect, useRef } from "react";

import {InputProps} from './interfaces';

import styles from './styles.module.css';

export const Input:React.FC<InputProps> = ({
    placeholder,
    value,
    onChangeText,
    inputType = 'text',
    disabled,
    customStyle,
    placeholderColor,
})=> {

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (placeholderColor && inputRef.current) {
            const styleEl = document.createElement('style');
            document.head.appendChild(styleEl);
            styleEl.sheet!.insertRule(`
                input.${styles.container}::placeholder {
                    color: ${placeholderColor};
                }
            `);
            return () => {
                document.head.removeChild(styleEl);
            };
        }
    }, [placeholderColor]);

    return (
        <input 
            ref={inputRef}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={e=> onChangeText(e.target.value)}
            type={inputType}
            className={styles.container}
            style={customStyle}
        />
    )
}