import React from "react";
import { RecapProps } from "./interfaces";
import styles from "./styles.module.css";

import { useCallback, useMemo } from "react";
import { formatNumberToPrice, formatTextMaxChars_15, formatedDate } from "../../../../functions";

import {
  Button, DesktopHedaderLight, FloatingRecapCard, MobileHeaderLight
} from "../../components";
import { useNavigation, useScreenSize } from "../../../../hooks";
import { enumNavigationPath } from "../../../../enums";

export const RecapComponentTemplateB: React.FC<RecapProps> = ({
  basket,
  deliveryData,
}) => {

  const {
    isMobile,
    isDesktop,
  } = useScreenSize();
  const {
    navigateTo,
    goBack,
  } = useNavigation();

  const totalBasket = useMemo(() => {
    let total = 0;

    basket.map((el) => {
      const price =
        el.productSellingPrice[el.productSellingPrice.length - 1].price;
      total += price * el.quantitySelected;
      el.extraSideDish?.map((supp) => {
        total += supp.price;
      });
    });

    return total;
  }, [basket]);

  const onButtonPress = useCallback(() => {
    navigateTo(enumNavigationPath.payment);
  }, [navigateTo]);

  const handleGoLeft = useCallback(() => {
    goBack();
  }, [goBack]);


  const renderRecapCard = useCallback(() => {

    return <FloatingRecapCard totalBasket={totalBasket} onButtonPress={onButtonPress} />
  }, [totalBasket, onButtonPress])

  const renderMobileAndTabletContent = useCallback(() => {
    return (
      <div className={styles.mobileContentContainer}>
        <div className={isDesktop ? styles.orderRefsDesc : styles.orderRefs}>
          {/* <p>Référence: {`WQR59A6`}</p> */}
          <p>Date de livraison: {formatedDate()}</p>
        </div>
        {/* Détails commande */}
        <div className={styles.orderDetailsContainer}>
          <p className={styles.commandeTitle}>Votre commande</p>
          <div>
            <div className={styles.itemRowTItle}>
              <p>
                Commande(s) {basket.length}
              </p>
              <p>
                Prix
              </p>

            </div>
            {
              basket.map((el, i) => {
                const price = el.productSellingPrice[el.productSellingPrice.length - 1].price;
                return (
                  <div key={i}
                    className={styles.recapCardItemRow}
                  >
                    <p>
                      {formatTextMaxChars_15(el.productName)} x{el.quantitySelected}
                    </p>
                    <p>
                      {formatNumberToPrice(price)} {'Fcfa'}
                    </p>

                  </div>
                )
              })
            }
          </div>
          <div className={styles.detailTotalContainer}>
            <p>Total à payer</p>
            <p>{formatNumberToPrice(totalBasket)} Fcfa</p>
          </div>
          {/* Infos livraison */}
          <div className={styles.deliveryDataContainer}>
            <p className={styles.commandeTitle}>Informations de livraison</p>
            <p className={styles.deliveryItemText}>{`${deliveryData.firstname} ${deliveryData.lastname}`}</p>
            <p className={styles.deliveryItemText}>{deliveryData.phone}</p>
            <p className={styles.deliveryItemText}>{deliveryData.city}</p>
            <p className={styles.deliveryItemText}>{deliveryData.street}</p>
            <p className={styles.deliveryItemText}>{deliveryData.adress}</p>
          </div>
        </div>

        <div>

        </div>
        {!isDesktop &&
          (<div className={styles.buttonContainer}>
            <Button
              buttonName="Payer"
              onButtonPress={onButtonPress}
              containerStyle={{
                padding: (isMobile || isDesktop) ? 10 : 15,
                paddingLeft: (isMobile || isDesktop) ? 60 : 100,
                paddingRight: (isMobile || isDesktop) ? 60 : 100,
                fontSize: (isMobile || isDesktop) ? 14 : 20,
                marginTop: (isMobile || isDesktop) ? 0 : 20,
                marginBottom: (isMobile || isDesktop) ? 0 : 20,
                backgroundColor: '#F6D18D',
                borderRadius: 10
              }}
            />
          </div>
          )}
      </div>
    )
  }, [isDesktop, basket, totalBasket, deliveryData, isMobile, onButtonPress])

  const renderDesktopContent = useCallback(() => {
    if (!isDesktop) return;
    return (
      <div className={styles.desktopContentRow}>
        {renderMobileAndTabletContent()}
        {renderRecapCard()}
      </div>
    )
  }, [isDesktop, renderRecapCard, renderMobileAndTabletContent])

  return (
    <div className={styles.container}>
      <DesktopHedaderLight />
      <MobileHeaderLight title="Recpatitulatif" />

      {isDesktop && <p className={styles.titleText}>
        Recapitulatif
      </p>}
      <div className={styles.separator} />
      {!isDesktop && renderMobileAndTabletContent()}
      {renderDesktopContent()}
    </div>
  );
};
