import React, { useCallback } from "react";
import { Button, DesktopHedaderLight } from "../../components";

import { SuccessProps } from "./interfaces";

import styles from "./styles.module.css";

import OrderChecked from "../../assets/icons/svgs/checkCommand.svg";
import Bill_img from "../../assets/icons/svgs/bill.svg";
import { useScreenSize } from "../../../../hooks";
// import DownloadIcon from "../../assets/icons/svgs/download.svg";
// import factureImg from "../../assets/images/facture.png";

export const SuccessComponentTemplateB: React.FC<SuccessProps> = ({
  onCleanBasket,
  onDownloadPress,
}) => {
  const { isDesktop } = useScreenSize()
  const handleDownloadFacture = useCallback(() => {
    onDownloadPress();
  }, [onDownloadPress]);

  return (
    <div className={styles.container}>
      <DesktopHedaderLight />
      {!isDesktop && (
        <React.Fragment>
          <p className={styles.titlePage}>
            Commande validée
          </p>
          <img
            src={OrderChecked}
            alt="commande check"
            className={styles.OrderChecked}
          />
        </React.Fragment>
      )}
      <div className={styles.contentWrapperRow}>
        <div>
          <p className={styles.thanksText}>
            Merci pour votre commande
          </p>
          <p className={styles.paragraphe}>
            Vous recevrez un mail sur l’état d’avancement de votre commande et sa date de livraison
          </p>
        </div>
        <div className={styles.billWrapper}>
          <img
            src={Bill_img}
            alt="bill"
            className={styles.bill}
          />
          <p onClick={handleDownloadFacture} className={styles.downloadLink}>Télécharger la facture</p>
        </div>
      </div>

      {!isDesktop && (
        <Button
          buttonName="Suivant"
          onButtonPress={onCleanBasket}
          containerStyle={{
            alignSelf: 'center',
            borderRadius: 10,
            marginTop: 30,
            width: 100,
            height:20
          }}
        />
      )}
    </div>
  );
};
