import React, { useEffect, useMemo, useState } from "react";

import { useControler } from './useControleur';
import { useNavigation } from "../../hooks";
import PlaceholderImage from "../../assets/svgImages/placeholder.svg";
import { HomeComponentTemplateA, HomeComponentTemplateB } from "../../templates";

export const Home: React.FC = () => {

  const { navigateTo } = useNavigation();
  const {
    banners,
    productByCategories,
    onSelectedProduct,
    onSelectedCategory,
    basket,
    sessionData,
    onAddProductInBasket,
    onSelectedProductQuantityDown,
  } = useControler();

  const templateType = useMemo(()=> {
    return sessionData?.template!
  }, [sessionData]);

  const [carrouselData, setCarrouselData] = useState<any[]>([]);

  const TEMPLATES:any = useMemo(() => {
    return {
      A: HomeComponentTemplateA,
      B: HomeComponentTemplateB
    }
  }, []);

  const images = useMemo(
    () => [PlaceholderImage, PlaceholderImage, PlaceholderImage],
    []
  );

  const TemplateComponent = useMemo(() => {
    return TEMPLATES[templateType];
  }, [
    TEMPLATES,
    templateType
  ]);

  useEffect(() => {
    let tmp: any[] = [];
    if (banners && banners?.listBanners && banners.listBanners.length > 0) {
      banners.listBanners.map((el: any) => {
        tmp.push({
          uri: el.img,
        });
      });
    } else {
      images.map((el) => {
        tmp.push({
          uri: el,
        });
      });
    }
    setCarrouselData(tmp);
  }, [banners, images]);

  return (
    <TemplateComponent
      carrouselData={carrouselData}
      productByCategories={productByCategories}
      navigateTo={navigateTo}
      onSelectedProduct={onSelectedProduct}
      onSelectedCategory={onSelectedCategory}
      onAddProductInBasket={onAddProductInBasket}
      onSelectedProductQuantityDown={onSelectedProductQuantityDown}
      basket={basket}
    />
  );
};
