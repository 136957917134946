export interface InputProps {
    placeholder: string
    value: string
    onChangeText: (e: string) => void
    inputType?: keyof inputType
    disabled?: boolean
    customStyle?: any
    placeholderColor?: string
    inputStyle?: InputStyle
    withBorders?: boolean
}

type inputType = {
    text: 'text'
    date: 'date'
    time: 'time'
    number: 'number'
}

export enum InputStyle {
    filled = 'filled',
    bottomBordered = 'bottomBordered'
}