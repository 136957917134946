import { CollectionDbEnum, Organisation } from "../../types";
import { collection, db, getDocs, query, where } from "../config";

export const OrganisationModel = () => {

  const getOrganisationData = async (parsedName: string) => {
    try {
      let organisation: Organisation | null = null;
      const q = query(
        collection(db, CollectionDbEnum.Organisations),
        where("organisationNameParsed", "==", parsedName)
      );

      const querySnapshot = await getDocs(q);

      for (const doc of querySnapshot.docs) {
        organisation = doc.data() as Organisation;
      }

      return {
        code: 200,
        data: organisation,
      };
    } catch (error) {
      return {
        code: 500,
      };
    }
  };

  const getAllOrganisation = async()=> {
    try {
      let organisations:Organisation[] = [];
      const q = query(
        collection(db, CollectionDbEnum.Organisations)
      );

      const querySnapshot = await getDocs(q);

      for (const doc of querySnapshot.docs) {
        const organisation = doc.data() as Organisation;
        organisations.push(organisation);
      }

      return {
        code: 200,
        data: organisations,
      }
    } catch (error) {
      return {
        code: 500
      }
    }
  }

  return {
    getOrganisationData,
    getAllOrganisation,
  };
};
