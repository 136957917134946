import React, { useMemo } from "react";
import { useController } from "./useController";
import { ProductComponentTemplateA,ProductComponentTemplateB } from "../../templates";

export const ProductPage:React.FC = ()=> {

  const {
    selectedProduct,
    selectedCategory,
    basket,
    sessionData,
    onSelectedProductQuantityUp,
    onSelectedProductQuantityDown,
    onAddProductInBasket,
    onSelectedProduct,
    onSelectedCategory
  } = useController();

  const templateType = useMemo(()=> {
    return sessionData?.template!
  }, [sessionData]);

  const TEMPLATES:any = useMemo(()=> {
    return {
      A: ProductComponentTemplateA,
      B:ProductComponentTemplateB
    }
  }, []);

  const TemplateComponent = useMemo(()=> {
    return TEMPLATES[templateType];
  }, [
    TEMPLATES, 
    templateType
  ]);

  return (
    <TemplateComponent 
      selectedProduct={selectedProduct}
      basket={basket}
      selectedCategory={selectedCategory}
      onAddProductInBasket={onAddProductInBasket}
      onSelectedProduct={onSelectedProduct}
      onSelectedProductQuantityUp={onSelectedProductQuantityUp}
      onSelectedProductQuantityDown={onSelectedProductQuantityDown}
      onSelectedCategory={onSelectedCategory}
    />
  )
}
