import { useDispatch, useSelector } from "react-redux";

import {
  SELECTED_PRODUCT_QUANTITY_DOWN_REQUEST,
  SELECTED_PRODUCT_QUANTITY_UP_REQUEST,
} from "../../modules";
import { basketType } from "../../types";
import { useSessionData } from "../../hooks";
import { useCallback } from "react";

export const useController = ()=> {

    const {
        sessionData, 
    } = useSessionData();
    const dispatch = useDispatch();

    const basket = useSelector((state: any)=> state.BasketReducers.basket);

    const onSelectedProductQuantityUp = useCallback((
        basket: basketType[],
        newItem: basketType
    )=> {
        return dispatch({
            type: SELECTED_PRODUCT_QUANTITY_UP_REQUEST,
            payload: { basket, newItem },
        });
    }, [dispatch]);

    const onSelectedProductQuantityDown = useCallback((
        basket: basketType[],
        item: basketType
    )=> {
        return dispatch({
            type: SELECTED_PRODUCT_QUANTITY_DOWN_REQUEST,
            payload: { basket, item },
        });
    }, [dispatch]);

    return {
        sessionData,
        basket,
        onSelectedProductQuantityUp,
        onSelectedProductQuantityDown,
    }
}
