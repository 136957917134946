import React, { useCallback, useEffect, useState } from "react";
import { Button, DesktopHedaderLight, Input, MobileHeaderLight } from "../../components";

import { LivraisonProps } from "./interfaces";

import styles from "./styles.module.css";
import { useNavigation, useScreenSize } from "../../../../hooks";
import { enumNavigationPath } from "../../../../enums";
import { InputStyle } from "../../components/Input/interfaces";
import back_button from '../../assets/icons/svgs/back-button.svg';

export const LivraisonComponentTemplateB: React.FC<LivraisonProps> = ({
  clientData,
  onSetClientDataLivraison,
}) => {
  const { navigateTo, goBack } = useNavigation();
  const { isDesktop, isMobile } = useScreenSize()
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [country, setCountry] = useState<string>("Côte d'Ivoire");
  const [city, setCity] = useState<string>("Abidjan");
  const [street, setStreet] = useState<string>("");
  const [adress, setAdress] = useState<string>("");

  useEffect(() => {
    if (clientData) {
      setFirstname(clientData.firstname);
      setLastname(clientData.lastname);
      setPhone(clientData.phone);
      setCountry(clientData.country);
      setCity(clientData.city);
      setStreet(clientData.street);
      setAdress(clientData.adress);
    }
  }, [clientData]);

  const handleButtonPress = useCallback(() => {
    if (firstname && lastname && phone && street) {
      onSetClientDataLivraison({
        firstname,
        lastname,
        phone,
        country,
        city,
        street,
        adress,
      });
      navigateTo(enumNavigationPath.recap);
    } else {
      alert("* Tous les champs doivent etre remplis !");
    }
  }, [
    adress,
    city,
    country,
    firstname,
    lastname,
    navigateTo,
    onSetClientDataLivraison,
    phone,
    street
  ]);

  const handleGoBack = useCallback(() => {
    goBack()
  }, [goBack])

  const renderInputContainer = useCallback(() => {
    const inputstyleCustomStyle = {
      borderColor: '#F6D18D',
      borderRadius: 0,
      height: 50,
      ...(isMobile && { width: 300, })
    }
    return (
      <div className={styles.inputsContainer}>
        <Input
          placeholder="Prenom"
          value={firstname}
          onChangeText={setFirstname}
          inputType="text"
          customStyle={inputstyleCustomStyle}
          inputStyle={InputStyle.filled}
          withBorders
        />
        <Input
          placeholder="Nom"
          value={lastname}
          onChangeText={setLastname}
          inputType="text"
          customStyle={inputstyleCustomStyle}
          inputStyle={InputStyle.filled}
          withBorders
        />
        <Input
          placeholder="Téléphone"
          value={phone}
          onChangeText={setPhone}
          inputType="number"
          customStyle={inputstyleCustomStyle}
          inputStyle={InputStyle.filled}
          withBorders
        />
        <Input
          placeholder="Pays"
          value={country}
          onChangeText={setCountry}
          inputType="text"
          disabled
          customStyle={inputstyleCustomStyle}
          inputStyle={InputStyle.filled}
          withBorders
        />
        <Input
          placeholder="Ville"
          value={city}
          onChangeText={setCity}
          inputType="text"
          disabled
          placeholderColor="grey"
          customStyle={inputstyleCustomStyle}
          inputStyle={InputStyle.filled}
          withBorders
        />
        <Input
          placeholder="Quartier"
          value={street}
          onChangeText={setStreet}
          inputType="text"
          customStyle={inputstyleCustomStyle}
          inputStyle={InputStyle.filled}
          withBorders
        />
        <textarea
          placeholder="Adresse"
          value={adress}
          onChange={(e) => setAdress(e.target.value)}
          rows={6}
          className={isMobile ? styles.textareaMobile : styles.textarea}
        />

        <Button
          buttonName="Suivant"
          onButtonPress={handleButtonPress}
          containerStyle={{
            marginTop: 40,
            marginBottom: 20,
            marginLeft: isDesktop ? 50 : undefined,
            padding: 10,
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 5,
            width: isDesktop ? 100 : 120,
            backgroundColor: "#F6D18D",
            fontFamily: 'ExpletusSans-Regular',
            ...(!isDesktop && { alignSelf: 'center' })
          }}
        />
      </div>
    )
  }, [
    isMobile,
    isDesktop,
    adress,
    city,
    country,
    firstname,
    handleButtonPress,
    lastname,
    phone,
    street
  ])

  const renderDesktopContent = useCallback(() => {
    if (!isDesktop) return
    return (
      <div>
        <div className={styles.fixedFormContaineer}>
          {renderInputContainer()}
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.leftContainer} >
            <img onClick={handleGoBack} src={back_button} className={styles.goBack} alt='go_back' />
          </div>
          <div className={styles.imageContainer} />
        </div>
      </div>
    )
  }, [isDesktop, handleGoBack, renderInputContainer])

  const renderMobileAndTabletContent = useCallback(() => {
    if (isDesktop) return;
    return (
      <div className={styles.mobileContainer}>
        <img className={styles.topImage} src="https://cdn.pixabay.com/photo/2023/08/08/17/03/butterfly-8177808_1280.jpg" alt="img_container" />

        {renderInputContainer()}
      </div>
    )
  }, [renderInputContainer, isDesktop])
  return (
    <div className={styles.container}>
      <MobileHeaderLight title="Livraison" />

      {renderDesktopContent()}
      {renderMobileAndTabletContent()}

    </div>
  );
};
