import React, { useCallback, useMemo, useState } from "react";
import { Button, DesktopHedaderLight, FloatingRecapCard, MobileHeaderLight } from "../../components";

import { PaymentProps } from "./interfaces";
import styles from "./styles.module.css";
import { useNavigation, useScreenSize } from "../../../../hooks";

import { enumToStringArray, generateOrderNumber } from "../../../../functions";


import CheckedIcon from '../../assets/icons/svgs/checked.svg';
import UncheckedIcon from '../../assets/icons/svgs/unChecked.svg';
import uuid from "react-uuid";
import { MobilePaymentEnum, OnlineOrderStatusEnum, PaymentMethodeEnum, Sale } from "../../../../types";
import { enumNavigationPath } from "../../../../enums";

export const PaymentComponentTemplateB: React.FC<PaymentProps> = ({
  onSelectedPayment,
  paymentTypeSave,
  onAddNewSale,
  sessionData,
  basket,
  deliveryData
}) => {

  const { goBack, navigateTo } = useNavigation();
  const {
    isDesktop,
    isMobile
  } = useScreenSize();
  const [selectedPaymentMode, setSelectedPaymentMode] =
    useState<PaymentMethodeEnum>(paymentTypeSave?.type!);

  const [selectedmobilMoneyMode, setSelectedmobilMoneyMode] = useState<string | null>(null);

  const paymentTypeList = useMemo(() => {
    return enumToStringArray(PaymentMethodeEnum);
  }, []);

  const mobilMoneyTypeList = useMemo(() => {
    return enumToStringArray(MobilePaymentEnum);
  }, []);

  const totalBasket = useMemo(() => {
    let total = 0;

    basket.map((el) => {
      const price =
        el.productSellingPrice[el.productSellingPrice.length - 1].price;
      total += price * el.quantitySelected;
      el.extraSideDish?.map((supp) => {
        total += supp.price;
      });
    });

    return total;
  }, [basket]);

  const handlePressButton = useCallback(() => {
    if (paymentTypeSave) {
      const date = new Date();
      const sale: Sale = {
        _organisationID: sessionData?._id!,
        _organisationType: sessionData?._organisationType!,
        _id: uuid(),
        orderNumber: generateOrderNumber(String(date)),
        basket: basket,
        totalToPay: totalBasket,
        totalPay: 0,
        reduction: 0,
        paymentMethode: {
          methode: paymentTypeSave.type,
          mobilePayment: paymentTypeSave.type === PaymentMethodeEnum.mobilMoney ? paymentTypeSave.isMobilPayment : null,
        },
        createAt: String(date),
        client: deliveryData,
        onlineOrderStatus: OnlineOrderStatusEnum.confirmed
      };

      onAddNewSale(sale);
    }
  }, [
    paymentTypeSave,
    basket,
    sessionData,
    totalBasket,
    deliveryData,
    onAddNewSale,
  ]);

  const handleSetPaymentMethode = useCallback(
    (paymentMethode: PaymentMethodeEnum) => {
      setSelectedPaymentMode(paymentMethode);
      if (paymentMethode !== PaymentMethodeEnum.mobilMoney) {
        onSelectedPayment({
          type: paymentMethode,
        });
      }
    },
    [onSelectedPayment]
  );

  const handleSetMobilPaymentMethode = useCallback(
    (paymentMobileType: MobilePaymentEnum) => {
      setSelectedmobilMoneyMode(paymentMobileType);
      onSelectedPayment({
        type: selectedPaymentMode,
        isMobilPayment: paymentMobileType
      });
    },
    [onSelectedPayment, selectedPaymentMode]
  );

  const handleGoLeft = useCallback(() => {
    goBack();
  }, [goBack]);

  const renderdesktopLeftIcon = useCallback(() => {
    if (!isDesktop) return;

    // return (
    //   <img
    //     src={LeftIcon}
    //     alt="left icon"
    //     className={styles.desktopLeftIcon}
    //     onClick={handleGoLeft}
    //   />
    // )
  }, [isDesktop, handleGoLeft]);

  const onExplorerPresss = useCallback(() => {
    navigateTo(enumNavigationPath.home);
  }, [navigateTo]);

  const onBasketPresss = useCallback(() => {
    navigateTo(enumNavigationPath.basket);
  }, [navigateTo]);
  return (
    <div className={styles.container}>

      <DesktopHedaderLight onPressExplorer={onExplorerPresss} onPressBasket={onBasketPresss} />
      <MobileHeaderLight title="Paiement" />
      <FloatingRecapCard totalBasket={totalBasket} onButtonPress={handlePressButton} />
      {isDesktop && (
        <div className={styles.pageTitle}>
          <p>Panier{' > '}</p>
          <p>Livraison{' > '}</p>
          <p>Paiement</p>
        </div>
      )}
      <div className={styles.separator} />

      <div className={styles.paymentsTypeContainer}>
        {
          paymentTypeList.map((paymentType, i) => {
            return (
              <div
                key={i}
                className={styles.paymentTypeItemContainer_1}
              >
                <div
                  className={styles.paymentTypeItemContainer}
                  onClick={() => handleSetPaymentMethode(paymentType)}
                >
                  <label className={styles.paymentTypeLabel}>
                    {paymentType}
                  </label>

                  <img
                    src={selectedPaymentMode === paymentType ? CheckedIcon : UncheckedIcon}
                    alt="checked or unchecked icon"
                    className={styles.checked}
                  />
                </div>
                {
                  (
                    selectedPaymentMode === PaymentMethodeEnum.mobilMoney &&
                    paymentType === PaymentMethodeEnum.mobilMoney
                  ) && (
                    <div className={styles.mobilPaymentContainer}>
                      {
                        mobilMoneyTypeList.map((mobilPayment, j) => {
                          return (
                            <div
                              key={j}
                              className={styles.mobilPaymentItemContainer}
                              onClick={() => handleSetMobilPaymentMethode(mobilPayment)}
                            >
                              <div className={selectedmobilMoneyMode === mobilPayment ? styles.customCheck : styles.customUncheck}></div>
                              {/* <div
                                className={
                                  selectedmobilMoneyMode === mobilPayment ?
                                    styles.checkedCustom : styles.unCheckedCustom
                                }
                              /> */}

                              <label
                                className={
                                  selectedmobilMoneyMode === mobilPayment ?
                                    styles.mobilPaymentLabelSelected : styles.mobilPaymentLabel
                                }
                              >
                                {mobilPayment}
                              </label>
                            </div>
                          )
                        })
                      }
                    </div>
                  )
                }
                <div className={styles.paymentCardMessage}>Veuillez vous diriger vers la caisse pour finaliser le paiement.</div>

              </div>
            )
          })
        }
      </div>

      {!isDesktop && <div className={styles.buttonContainer}>
        <Button
          buttonName="Payer"
          onButtonPress={handlePressButton}
          containerStyle={{
            marginTop: 50,
            width: isMobile ? 100 : 200,
            borderRadius: 10,
          }}
        />
      </div>
      }

    </div>
  );
};
