import React from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import styles from './styles.module.css';
import {ResponsiveCarouselProps} from './interfaces';
import { useScreenSize } from "../../../../hooks";


export const ResponsiveCarousel:React.FC<ResponsiveCarouselProps> = ({
    carrouselArray
})=> {

    const {isMobile} = useScreenSize();

    return (
        <div
            className={styles.container}
        >
            <Carousel
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                dynamicHeight={false}
                infiniteLoop
                autoPlay
                swipeable
                centerMode
            >
                {
                    carrouselArray.map((el, i)=> {
                        return (
                            <div
                                key={i}
                                style={{
                                    marginLeft: isMobile ? 5 : 10,
                                    marginRight: isMobile ? 5 : 10
                                }}
                            >
                                <div
                                    style={{
                                        backgroundImage: `url(${el.uri})`
                                    }}
                                    className={styles.imgBackground}
                                />
                            </div>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}